export const CREATE_PRICE_RULE = "CREATE_PRICE_RULE";
export const CREATE_PRICE_RULE_SUCCESS = "CREATE_PRICE_RULE_SUCCESS";
export const CREATE_PRICE_RULE_FAILED = "CREATE_PRICE_RULE_FAILED";

export const UPDATE_PRICE_RULE = "UPDATE_PRICE_RULE";
export const UPDATE_PRICE_RULE_SUCCESS = "UPDATE_PRICE_RULE_SUCCESS";
export const UPDATE_PRICE_RULE_FAILED = "UPDATE_PRICE_RULE_FAILED";

export const GET_ALL_PRICE_RULES = "GET_ALL_PRICE_RULES";
export const GET_ALL_PRICE_RULES_SUCCESS = "GET_ALL_PRICE_RULES_SUCCESS";
export const GET_ALL_PRICE_RULES_FAILED = "GET_ALL_PRICE_RULES_FAILED";

export const GET_PRICE_RULE_DETAIL = "GET_PRICE_RULE_DETAIL";
export const GET_PRICE_RULE_DETAIL_SUCCESS = "GET_PRICE_RULE_DETAIL_SUCCESS";
export const GET_PRICE_RULE_DETAIL_FAILED = "GET_PRICE_RULE_DETAIL_FAILED";

export const TOGGLE_PRICE_RULES_ITEM = "TOGGLE_PRICE_RULES_ITEM";
export const ENABLE_ALL_PRICE_RULES_ITEMS = "ENABLE_ALL_PRICE_RULES_ITEMS";
export const DISABLE_ALL_PRICE_RULES_ITEMS = "DISABLE_ALL_PRICE_RULES_ITEMS";

export const DELETE_PRICE_RULE = "DELETE_PRICE_RULE";
export const DELETE_PRICE_RULE_SUCCESS = "DELETE_PRICE_RULE_SUCCESS";
export const DELETE_PRICE_RULE_FAILED = "DELETE_PRICE_RULE_FAILED";

export const GET_PRICE_RULE_OPTIONS = "GET_PRICE_RULE_OPTIONS";
export const GET_PRICE_RULE_OPTIONS_SUCCESS = "GET_PRICE_RULE_OPTIONS_SUCCESS";
export const GET_PRICE_RULE_OPTIONS_FAILED = "GET_PRICE_RULE_OPTIONS_FAILED";

export const ADD_DURATION_PRICE_RULE = "ADD_DURATION_PRICE_RULE";
export const EDIT_DURATION_PRICE_RULE = "EDIT_DURATION_PRICE_RULE";
export const DELETE_DURATION_PRICE_RULE = "DELETE_DURATION_PRICE_RULE";
export const CLEAR_DURATION_PRICE_RULE = "CLEAR_DURATION_PRICE_RULE";
export const CONTRACT_IS_UPDATING = "CONTRACT_IS_UPDATING";
export const SET_PRICE_RULE_CUSTOM_BOX_UPDATING =
  "SET_PRICE_RULE_CUSTOM_BOX_UPDATING";

export const GET_PRICE_RULE_CONTRACT_LOCATIONS =
  "GET_PRICE_RULE_CONTRACT_LOCATIONS";
export const GET_PRICE_RULE_CONTRACT_LOCATIONS_SUCCESS =
  "GET_PRICE_RULE_CONTRACT_LOCATIONS_SUCCESS";
export const GET_PRICE_RULE_CONTRACT_LOCATIONS_FAILED =
  "GET_PRICE_RULE_CONTRACT_LOCATIONS_FAILED";

export const GET_PRICE_RULE_REGION_COUNTRIES =
  "GET_PRICE_RULE_REGION_COUNTRIES";
export const GET_PRICE_RULE_REGION_COUNTRIES_SUCCESS =
  "GET_PRICE_RULE_REGION_COUNTRIES_SUCCESS";
export const GET_PRICE_RULE_REGION_COUNTRIES_FAILED =
  "GET_PRICE_RULE_REGION_COUNTRIES_FAILED";

export const GET_PRICE_RULE_COUNTRIES_LOCATIONS =
  "GET_PRICE_RULE_COUNTRIES_LOCATIONS";
export const GET_PRICE_RULE_COUNTRIES_LOCATIONS_SUCCESS =
  "GET_PRICE_RULE_COUNTRIES_LOCATION_SUCCESSS";
export const GET_PRICE_RULE_COUNTRIES_LOCATIONS_FAILED =
  "GET_PRICE_RULE_COUNTRIES_LOCATIONS_FAILED";

export const createPriceRule = (payload, setSubmiting, hideModal) => ({
  type: CREATE_PRICE_RULE,
  payload,
  setSubmiting,
  hideModal,
});

export const updatePriceRule = (payload, setSubmiting, hideModal) => ({
  type: UPDATE_PRICE_RULE,
  payload,
  setSubmiting,
  hideModal,
});

export const deletePriceRule = (id) => ({
  type: DELETE_PRICE_RULE,
  id,
});

export const getAllPriceRules = (payload) => ({
  type: GET_ALL_PRICE_RULES,
  payload,
});

export const getPriceRuleDetail = (id, showModal, filter) => ({
  type: GET_PRICE_RULE_DETAIL,
  id,
  showModal,
  filter,
});

export const togglePrcieRulesItem = (id, name) => ({
  type: TOGGLE_PRICE_RULES_ITEM,
  id,
  name,
});

export const getPriceRulesOptions = (filter = "") => ({
  type: GET_PRICE_RULE_OPTIONS,
  filter,
});

export const enableAllPrcieRulesItem = (name) => ({
  type: ENABLE_ALL_PRICE_RULES_ITEMS,
  name,
});

export const disableAllPrcieRulesItem = (name) => ({
  type: DISABLE_ALL_PRICE_RULES_ITEMS,
  name,
});

export const addDurationPriceRule = (payload) => ({
  type: ADD_DURATION_PRICE_RULE,
  payload,
});

export const editDurationPriceRule = (payload) => ({
  type: EDIT_DURATION_PRICE_RULE,
  payload,
});

export const deleteDurationPriceRule = (payload) => ({
  type: DELETE_DURATION_PRICE_RULE,
  payload,
});

export const clearDurationPriceRule = () => ({
  type: CLEAR_DURATION_PRICE_RULE,
});

export const getPriceRuleContractLocation = (payload) => ({
  type: GET_PRICE_RULE_CONTRACT_LOCATIONS,
  payload,
});

export const setContractUpdating = (payload) => ({
  type: CONTRACT_IS_UPDATING,
  payload,
});

export const getPriceRuleRegionCountries = (payload) => ({
  type: GET_PRICE_RULE_REGION_COUNTRIES,
  payload,
});

export const getPriceRuleCountriesLocations = (payload) => ({
  type: GET_PRICE_RULE_COUNTRIES_LOCATIONS,
  payload,
});

export const setPriceRuleCustomBoxUpdating = (name, payload) => ({
  type: SET_PRICE_RULE_CUSTOM_BOX_UPDATING,
  name,
  payload,
});
