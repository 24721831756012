import { Observable } from "rxjs";
import { ofType } from "redux-observable";
import { switchMap } from "rxjs/operators";

import {
  MAKE_CAR_RESERVATION,
  MAKE_CAR_RESERVATION_FAILED,
  MAKE_CAR_RESERVATION_SUCCESS,
} from "../actions/carReservation.action";
import { BASE, MAKE_CAR_RESERVATION_API } from "./../../config/api";
import { axiosClient } from "./auth.epic";

export class CarReservationEpic {
  static makeCarReservation = (action$) =>
    action$.pipe(
      ofType(MAKE_CAR_RESERVATION),
      switchMap(({ payload, dispatch, setWorkingStepId, setCurrentStepId }) => {
        return new Observable(async (observer) => {
          try {
            const updatedPayload = {
              ...payload,
              driver: {
                ...payload.driver,
                country: payload.driver.country.value,
                phonecode: payload.driver.phonecode.value,
              },
            };
            const { data } = await axiosClient.post(
              `${MAKE_CAR_RESERVATION_API}`,
              updatedPayload
            );
            if (data.data) {
              observer.next({
                type: MAKE_CAR_RESERVATION_SUCCESS,
                reservationVoucher: data.data,
                message: "Car Booked Successfully",
              });
              dispatch(setWorkingStepId(4));
              dispatch(setCurrentStepId(4));
            } else {
              observer.next({
                type: MAKE_CAR_RESERVATION_FAILED,
                message: data.message,
              });
            }
          } catch (err) {
            observer.next({
              type: MAKE_CAR_RESERVATION_FAILED,
              message: err.response.data.message,
            });
          }
        });
      })
    );
}
