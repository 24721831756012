import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT,
  SET_CURRENCIES,
  SET_CUURENCIES_SUCCESS,
  SET_CUURENCIES_FAILED,
} from "./../actions/auth.action";
import {
  SEARCH_AVAILABLE_CARS,
  SEARCH_AVAILABLE_CARS_FAILED,
  SEARCH_AVAILABLE_CARS_SUCCESS,
} from "./../actions/availableCars.action";
import {
  SEARCH_BOOKINGS,
  SEARCH_BOOKINGS_FAILED,
  SEARCH_BOOKINGS_SUCCESS,
  CANCEL_BOOKINGS,
  CANCEL_BOOKINGS_FAILED,
  CANCEL_BOOKINGS_SUCCESS,
  SET_REMINDER,
  SET_REMINDER_SUCCESS,
  SET_REMINDER_FAILED,
  SEND_CHECKIN_EMAIL,
  SEND_CHECKIN_EMAIL_SUCCESS,
  SEND_CHECKIN_EMAIL_FAILED,
  SEND_CHECKOUT_EMAIL,
  SEND_CHECKOUT_EMAIL_SUCCESS,
  SEND_CHECKOUT_EMAIL_FAILED,
  FETCH_BOOKING_DETAILS,
  FETCH_BOOKING_DETAILS_SUCCESS,
  FETCH_BOOKING_DETAILS_FAILED
} from "./../actions/bookings.action";
import {
  MAKE_CAR_RESERVATION,
  MAKE_CAR_RESERVATION_FAILED,
  MAKE_CAR_RESERVATION_SUCCESS,
} from "./../actions/carReservation.action";
import {
  CREATE_CONTRACT,
  CREATE_CONTRACT_FAILED,
  CREATE_CONTRACT_SUCCESS,
  GET_CONTRACT_DETAIL,
  GET_CONTRACT_DETAIL_FAILED,
  GET_CONTRACT_DETAIL_SUCCESS,
  UPDATE_CONTRACT,
  UPDATE_CONTRACT_FAILED,
  UPDATE_CONTRACT_SUCCESS,
  DELETE_CONTRACT_FAILED,
  DELETE_CONTRACT,
  DELETE_CONTRACT_SUCCESS,
} from "./../actions/contracts.action";
import {
  CREATE_EXTRAS,
  CREATE_EXTRAS_FAILED,
  CREATE_EXTRAS_SUCCESS,
  UPDATE_EXTRAS,
  UPDATE_EXTRAS_SUCCESS,
  UPDATE_EXTRAS_FAILED,
  DELETE_EXTRAS,
  DELETE_EXTRAS_FAILED,
  DELETE_EXTRAS_SUCCESS,
  GET_EXTRAS_DETAIL,
  GET_EXTRAS_DETAIL_FAILED,
  GET_EXTRAS_DETAIL_SUCCESS,
} from "./../actions/extras.action";
import {
  CREATE_PRICE_RULE,
  CREATE_PRICE_RULE_FAILED,
  CREATE_PRICE_RULE_SUCCESS,
  UPDATE_PRICE_RULE,
  GET_PRICE_RULE_DETAIL,
  GET_PRICE_RULE_DETAIL_FAILED,
  UPDATE_PRICE_RULE_FAILED,
  UPDATE_PRICE_RULE_SUCCESS,
  DELETE_PRICE_RULE,
  DELETE_PRICE_RULE_FAILED,
  DELETE_PRICE_RULE_SUCCESS,
  GET_PRICE_RULE_DETAIL_SUCCESS,
} from "./../actions/priceRules.action";
import {
  GET_INTEGRATIONS,
  GET_INTEGRATIONS_FAILED,
  GET_SUPPLIERS,
  GET_SUPPLIERS_FAILED,
  GET_INTEGRATIONS_SUCCESS,
  GET_SUPPLIERS_SUCCESS,
} from "./../actions/supply.action";
import {
  SET_CUSTOMER_STATUS,
  SET_CUSTOMER_STATUS_FAILED,
  SET_CUSTOMER_STATUS_SUCCESS,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILED,
} from "./../actions/customer.action";

const INITIAL_STATE = {
  response: false,
  type: "",
  message: "",
  isLoading: false,
};

export const RESET_STATE = "RESET_STATE";

export const AppReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN:
    case SEARCH_AVAILABLE_CARS:
    case SEARCH_BOOKINGS:
    case MAKE_CAR_RESERVATION:
    case CREATE_CONTRACT:
    case GET_CONTRACT_DETAIL:
    case UPDATE_CONTRACT:
    case DELETE_CONTRACT:
    case CREATE_EXTRAS:
    case UPDATE_EXTRAS:
    case DELETE_EXTRAS:
    case GET_EXTRAS_DETAIL:
    case CREATE_PRICE_RULE:
    case GET_PRICE_RULE_DETAIL:
    case UPDATE_PRICE_RULE:
    case DELETE_PRICE_RULE:
    case GET_INTEGRATIONS:
    case GET_SUPPLIERS:
    case CANCEL_BOOKINGS:
    case SET_REMINDER:
    case SEND_CHECKIN_EMAIL:
    case SEND_CHECKOUT_EMAIL:
    case SET_CUSTOMER_STATUS:
    case UPDATE_CUSTOMER:
    case SET_CURRENCIES:
    case FETCH_BOOKING_DETAILS:
      return Object.assign({}, state, { isLoading: true });
    case LOGIN_SUCCESS:
    case LOGOUT:
    case SEARCH_AVAILABLE_CARS_SUCCESS:
    case SEARCH_BOOKINGS_SUCCESS:
    case MAKE_CAR_RESERVATION_SUCCESS:
    case CREATE_CONTRACT_SUCCESS:
    case UPDATE_CONTRACT_SUCCESS:
    case CREATE_EXTRAS_SUCCESS:
    case UPDATE_EXTRAS_SUCCESS:
    case DELETE_EXTRAS_SUCCESS:
    case CREATE_PRICE_RULE_SUCCESS:
    case UPDATE_PRICE_RULE_SUCCESS:
    case GET_PRICE_RULE_DETAIL_SUCCESS:
    case GET_EXTRAS_DETAIL_SUCCESS:
    case GET_CONTRACT_DETAIL_SUCCESS:
    case DELETE_PRICE_RULE_SUCCESS:
    case CANCEL_BOOKINGS_SUCCESS:
    case SET_REMINDER_SUCCESS:
    case GET_INTEGRATIONS_SUCCESS:
    case GET_SUPPLIERS_SUCCESS:
    case SET_CUSTOMER_STATUS_SUCCESS:
    case UPDATE_CUSTOMER_SUCCESS:
    case DELETE_CONTRACT_SUCCESS:
    case SET_CUURENCIES_SUCCESS:
    case SEND_CHECKIN_EMAIL_SUCCESS:
    case SEND_CHECKOUT_EMAIL_SUCCESS:
    case FETCH_BOOKING_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        type: "Success",
        response: true,
        message: action.message,
      });
    case LOGIN_FAILED:
    case SEARCH_AVAILABLE_CARS_FAILED:
    case SEARCH_BOOKINGS_FAILED:
    case MAKE_CAR_RESERVATION_FAILED:
    case CREATE_CONTRACT_FAILED:
    case GET_CONTRACT_DETAIL_FAILED:
    case UPDATE_CONTRACT_FAILED:
    case CREATE_EXTRAS_FAILED:
    case UPDATE_EXTRAS_FAILED:
    case DELETE_EXTRAS_FAILED:
    case GET_EXTRAS_DETAIL_FAILED:
    case CREATE_PRICE_RULE_FAILED:
    case UPDATE_PRICE_RULE_FAILED:
    case GET_PRICE_RULE_DETAIL_FAILED:
    case DELETE_PRICE_RULE_FAILED:
    case GET_SUPPLIERS_FAILED:
    case GET_INTEGRATIONS_FAILED:
    case CANCEL_BOOKINGS_FAILED:
    case SET_REMINDER_FAILED:
    case SET_CUSTOMER_STATUS_FAILED:
    case UPDATE_CUSTOMER_FAILED:
    case DELETE_CONTRACT_FAILED:
    case SET_CUURENCIES_FAILED:
    case SEND_CHECKIN_EMAIL_FAILED:
    case SEND_CHECKOUT_EMAIL_FAILED:
    case FETCH_BOOKING_DETAILS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        type: "Error",
        response: true,
        message: action.message,
      });
    case RESET_STATE:
      return { ...state, response: false, message: "", type: "" };
    default:
      return state;
  }
};
