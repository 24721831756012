import { Observable } from "rxjs";
import { ofType } from "redux-observable";
import { switchMap } from "rxjs/operators";

import {
  SEARCH_BOOKINGS,
  SEARCH_BOOKINGS_FAILED,
  SEARCH_BOOKINGS_SUCCESS,
  CANCEL_BOOKINGS,
  CANCEL_BOOKINGS_FAILED,
  CANCEL_BOOKINGS_SUCCESS,
  SET_REMINDER,
  SET_REMINDER_FAILED,
  SET_REMINDER_SUCCESS,
  SEND_CHECKIN_EMAIL,
  SEND_CHECKIN_EMAIL_SUCCESS,
  SEND_CHECKIN_EMAIL_FAILED,
  SEND_CHECKOUT_EMAIL,
  SEND_CHECKOUT_EMAIL_SUCCESS,
  SEND_CHECKOUT_EMAIL_FAILED,
  FETCH_BOOKING_DETAILS,
  FETCH_BOOKING_DETAILS_SUCCESS,
  FETCH_BOOKING_DETAILS_FAILED
} from "../actions/bookings.action";
import { HIDE_MODAL } from "../actions/modal.action";
import {
  BASE,
  SEARCH_BOOKINGS_API,
  CANCEL_BOOKINGS_API,
  SET_REMINDER_API,
  SEND_CHECKIN_EMAIL_API,
  SEND_CHECKOUT_EMAIL_API,
  FETCH_BOOKING_DETAILS_API
} from "./../../config/api";

import { axiosClient } from "./auth.epic";

export class BookingsEpic {
  static searchBookings = (action$) =>
    action$.pipe(
      ofType(SEARCH_BOOKINGS),
      switchMap(({ payload }) => {
        return new Observable(async (observer) => {
          try {
            const { data } = await axiosClient.post(
              `${SEARCH_BOOKINGS_API}?page=${payload.page}&limit=${payload.limit}`,
              payload
            );
            if (data.data && data.data.length > 0) {
              observer.next({
                type: SEARCH_BOOKINGS_SUCCESS,
                bookings: data.data,
                pagination: data.pagination,
                message: "Bookings Successfully Fetched",
              });
            } else {
              observer.next({
                type: SEARCH_BOOKINGS_FAILED,
                message: "No Bookings Found",
              });
            }
          } catch (err) {
            observer.next({
              type: SEARCH_BOOKINGS_FAILED,
              message: "Error in Bookings Fetching",
            });
          }
        });
      })
    );
  static cancelBooking = (action$) =>
    action$.pipe(
      ofType(CANCEL_BOOKINGS),
      switchMap(({ payload }) => {
        return new Observable(async (observer) => {
          try {
            const { data } = await axiosClient.post(
              `${CANCEL_BOOKINGS_API}`,
              payload
            );
            if (data.success) {
              observer.next({
                type: CANCEL_BOOKINGS_SUCCESS,
                payload: data.data,
                message: data.msg,
              });
              observer.next({
                type: HIDE_MODAL,
                payload: "bookingCanelConfirmModal",
              });
            } else {
              observer.next({
                type: CANCEL_BOOKINGS_FAILED,
                message: "No Bookings Found",
              });
              observer.next({
                type: HIDE_MODAL,
                payload: "bookingCanelConfirmModal",
              });
            }
          } catch (err) {
            observer.next({
              type: CANCEL_BOOKINGS_FAILED,
              message: "Error in Bookings Cancelling",
            });
            observer.next({
              type: HIDE_MODAL,
              payload: "bookingCanelConfirmModal",
            });
          }
        });
      })
    );
  static setReminder = (action$) =>
    action$.pipe(
      ofType(SET_REMINDER),
      switchMap(({ payload }) => {
        return new Observable(async (observer) => {
          try {
            const { data } = await axiosClient.post(
              `${SET_REMINDER_API}`,
              payload
            );
            if (data.success) {
              observer.next({
                type: SET_REMINDER_SUCCESS,
                // payload: data.data,
                message: data.message,
              });
              observer.next({
                type: HIDE_MODAL,
                payload: "bookingCanelConfirmModal",
              });
            } else {
              observer.next({
                type: SET_REMINDER_FAILED,
                message: data.message,
              });
              observer.next({
                type: HIDE_MODAL,
                payload: "bookingCanelConfirmModal",
              });
            }
          } catch (err) {
            observer.next({
              type: CANCEL_BOOKINGS_FAILED,
              message: err.response.data.message,
            });
            observer.next({
              type: HIDE_MODAL,
              payload: "bookingCanelConfirmModal",
            });
          }
        });
      })
    );
  static sendCheckinEmail = (action$) =>
    action$.pipe(
      ofType(SEND_CHECKIN_EMAIL),
      switchMap(({ payload }) => {
        return new Observable(async (observer) => {
          try {
            const { data } = await axiosClient.post(
              `${SEND_CHECKIN_EMAIL_API}`,
              payload
            );
            if (data.success) {
              observer.next({
                type: SEND_CHECKIN_EMAIL_SUCCESS,
                // payload: data.data,
                message: data.message,
              });
            } else {
              observer.next({
                type: SEND_CHECKIN_EMAIL_FAILED,
                message: data.message,
              });
            }
          } catch (err) {
            observer.next({
              type: SEND_CHECKIN_EMAIL_FAILED,
              message: err.response.data.message,
            });
          }
        });
      })
    );
  static sendCheckoutEmail = (action$) =>
    action$.pipe(
      ofType(SEND_CHECKOUT_EMAIL),
      switchMap(({ payload }) => {
        return new Observable(async (observer) => {
          try {
            const { data } = await axiosClient.post(
              `${SEND_CHECKOUT_EMAIL_API}`,
              payload
            );
            if (data.success) {
              observer.next({
                type: SEND_CHECKOUT_EMAIL_SUCCESS,
                // payload: data.data,
                message: data.message,
              });
            } else {
              observer.next({
                type: SEND_CHECKOUT_EMAIL_FAILED,
                message: data.message,
              });
            }
          } catch (err) {
            observer.next({
              type: SEND_CHECKOUT_EMAIL_FAILED,
              message: err.response.data.message,
            });
          }
        });
      })
    );
  static fetchBookingDetails = (action$) =>
    action$.pipe(
      ofType(FETCH_BOOKING_DETAILS),
      switchMap(({ id }) => {
        return new Observable(async (observer) => {
          try {
            const { data } = await axiosClient.get(
              `${FETCH_BOOKING_DETAILS_API}/${id}`
            );
            if (data.data) {
              observer.next({
                type: FETCH_BOOKING_DETAILS_SUCCESS,
                bookingDetail: data.data,
                message: "Booking Detail Successfully Fetched",
              });
            } else {
              observer.next({
                type: FETCH_BOOKING_DETAILS_FAILED,
                message: "No Booking Detail Found",
              });
            }
          } catch (err) {
            observer.next({
              type: FETCH_BOOKING_DETAILS_FAILED,
              message: "Error in Booking Detail Fetching",
            });
          }
        });
      })
    );
}
