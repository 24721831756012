export const SEARCH_AVAILABLE_CARS = "SEARCH_AVAILABLE_CARS";
export const SEARCH_AVAILABLE_CARS_SUCCESS = "SEARCH_AVAILABLE_CARS_SUCCESS";
export const SEARCH_AVAILABLE_CARS_FAILED = "SEARCH_AVAILABLE_CARS_FAILED";

export const SET_AVAILABLE_CARS_FILTER = "SET_AVAILABLE_CARS_FILTER";
export const SET_WORKING_STEP_ID = "SET_WORKING_STEP_ID";

export const SET_CURRENT_STEP_ID = "SET_CURRENT_STEP_ID";
export const CLEAR_FILTERS = "CLEAR_FILTERS";

export const GET_SEARCH_VEHICLE_FILTER = "GET_SEARCH_VEHICLE_FILTER";
export const GET_SEARCH_VEHICLE_FILTER_SUCCESS =
  "GET_SEARCH_VEHICLE_FILTER_SUCCESS";
export const GET_SEARCH_VEHICLE_FILTER_FAILED =
  "GET_SEARCH_VEHICLE_FILTER_FAILED";

export const searchAvailableCars = (payload, token) => ({
  type: SEARCH_AVAILABLE_CARS,
  payload,
  token,
});

export const setCarSearchFilter = (key, value) => ({
  type: SET_AVAILABLE_CARS_FILTER,
  key,
  value,
});

export const getSearchVehicleFilter = (token) => ({
  type: GET_SEARCH_VEHICLE_FILTER,
  token,
});

export const setWorkingStepId = (payload) => ({
  type: SET_WORKING_STEP_ID,
  payload,
});

export const setCurrentStepId = (payload) => ({
  type: SET_CURRENT_STEP_ID,
  payload,
});
