import React from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";

const Login = React.lazy(() => import("./../containers/Pages/Login/index"));
const Register = React.lazy(() => import("./../containers/Pages/Register"));
const Page404 = React.lazy(() => import("./../containers/Pages/Page404"));
const Page500 = React.lazy(() => import("./../containers/Pages/Page500"));
const DefaultLayout = React.lazy(() =>
  import("./../containers/DefaultLayout/index")
);

const Authentication = ({ component: Component, authed = false, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        authed ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const PublicRoutes = () => {
  const Auth = useSelector(state => state.Auth);
  return (
    <Router>
      <React.Suspense fallback={loading()}>
        <Switch>
          <Route
            exact
            path="/login"
            name="Login Page"
            render={props => <Login {...props} />}
          />
          <Route
            exact
            path="/register"
            name="Register Page"
            render={props => <Register {...props} />}
          />
          <Route
            exact
            path="/404"
            name="Page 404"
            render={props => <Page404 {...props} />}
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            render={props => <Page500 {...props} />}
          />
          <Authentication
            path="/"
            name="Home"
            authed={Auth.isUserLoggedIn}
            component={DefaultLayout}
          />
        </Switch>
      </React.Suspense>
    </Router>
  );
};

export default PublicRoutes;
