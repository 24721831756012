export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

export const showModal = modalName => ({
  type: SHOW_MODAL,
  payload: modalName
});

export const hideModal = modalName => ({
  type: HIDE_MODAL,
  payload: modalName
});
