export const BASE = "https://api.rentalmoose.com/admin";
// export const BASE = "http://192.168.0.17:8000/admin";
export const LOGIN_API = "/login";
export const CURRENCIES_LIST_API = "/currencyList"; //POST
export const SEARCH_BOOKINGS_API = "/searchBookings"; //POST
export const CANCEL_BOOKINGS_API = "/booking/cancel"; //POST
export const SET_REMINDER_API = "/booking/reminder"; //POST
export const SEND_CHECKIN_EMAIL_API = "/booking/checkin-email"; //POST
export const SEND_CHECKOUT_EMAIL_API = "/booking/checkout-email"; //POST
export const FETCH_BOOKING_DETAILS_API = "/bookingDetail"; // GET
export const AVAILABLE_CARS_API = "/availableVehicles"; //POST
export const MAKE_CAR_RESERVATION_API = "/booking/reserve";
export const GET_SEARCH_VEHICLE_FILTERS_API =
  "https://api.rentalmoose.com/search-vehicle-filters"; //GET
export const GET_SEARCH_RESULTS =
  "https://api.rentalmoose.com/admin/search_history"; //GET
//POST

/**
 * Contract APIS
 */
export const GET_CONTRACT_META_INFO_API = "/contract/meta-info"; //GET
export const GET_SUPPLIER_REGIONS_API =
  "/supplier-country-regions?supplier_id="; //GET
export const GET_SUPPLIER_LOCATIONS_API =
  "/supplier-country-locations?supplier_id="; //GET
export const GET_ALL_CONTRACTS_API = "/contract/list"; //GET
export const CREATE_CONTRACT_API = "/contract/create"; //POST
export const GET_CONTRACT_DETAIL_API = "/contract/"; //GET
export const UPDATE_CONTRACT_API = "/contract/update"; //PUT
export const DELETE_CONTRACT_API = "/contract/delete"; //DELETE

/**
 * Price Rule APIS
 */
export const CREATE_PRICE_RULE_API = "/pricing-rule/create";
export const UPDATE_PRICE_RULE_API = "/pricing-rule/update";
export const DELETE_PRICE_RULE_API = "/pricing-rule/delete/";
export const GET_ALL_PRICE_RULES_API = "/pricing-rule/list"; // GET
export const GET_PRICE_RULE_DETAIL_API = "/pricing-rule/";
export const GET_PRICE_RULE_OPTIONS_API = "/pricing-rule/options";
export const GET_PRICE_RULE_CONTRACT_LOCATIONS_API = "/contract/locations";

/**
 * Supply APIS
 */
export const GET_INTEGRATIONS_API = "/integration-list"; //GET
export const GET_SUPPLIERS_API = "/supplier-list"; //GET

/**
 * Extras APIs
 */
export const CREATE_EXTRAS_API = "/extras/create"; //POST
export const UPDATE_EXTRAS_API = "/extras/update"; //DELETE
export const GET_ALL_EXTRAS_API = "/extras/list"; //GET
export const GET_EXTRAS_DETAIL_API = "/extras/details/"; //GET
export const GET_EXTRAS_OPTIONS_API = "/extras/options"; //GET
export const DELETE_EXTRAS_API = "/extras/delete/";
export const GET_EXTRAS_CONTRACT_LOCATIONS_API = "/contract/locations";
export const GET_EXTRAS_REGION_COUNTRIES_API = "/contract/region-countries"; //POST
export const GET_EXTRAS_COUNTRIES_LOCATION_API = "/contract/country-stations"; //POST

export const SEARCH_DRIVER_API = "/searchDrivers?search=";
export const SEARCH_SUPPLIER_API = "/searchSuppliers?search=";
export const SEARCH_VEHICLE_API = "/searchVehicles?search=";
export const SEARCH_STATION_API = "/searchStationsUpdate?search=";

export const GET_CUSTOMERS_API = "/user/list"; // GET
export const SET_CUSTOMER_STATUS_API = "/user/"; // POST
export const UPDATE_CUSTOMER_API = "/updateUser"; // POST
export const SEARCH_AVAILABLE_CARS_FILTER_API = "/vehicle/filters";

export const GET_REGION_COUNTRIES_API = "/region-countries"; //POST
export const GET_PRICE_RULE_REGION_COUNTRIES_API = "/contract/region-countries"; //POST
export const GET_PRICE_RULE_COUNTRIES_LOCATION_API =
  "/contract/country-stations"; //POST
/**
 * SwitchPayment APIs
 */
export const GET_PAYMENT_MODE_API = "/get-Payment-mode"; //
export const SET_PAYMENT_MODE_API = "/set-Payment-mode"; //Post
export const SEND_OTP_API = "/send-OTP"; //Post
export const VERIFY_OTP_API = "/verify-OTP"; //Post

/**
 * Coupon APIs
 */
export const GET_COUPON_LIST_API = "/coupon/list"; //get
export const CREATE_COUPON_API = "/coupon/create"; //POST
export const DELETE_COUPON_API = "/coupon/delete"; //DELETE
export const GET_COUPON_DETAIL_API = "/coupon/"; //GET
export const UPDATE_COUPON_API = "/coupon/update";
