import {
  GET_ALL_PRICE_RULES,
  GET_ALL_PRICE_RULES_FAILED,
  GET_ALL_PRICE_RULES_SUCCESS,
  CREATE_PRICE_RULE,
  CREATE_PRICE_RULE_FAILED,
  CREATE_PRICE_RULE_SUCCESS,
  UPDATE_PRICE_RULE,
  UPDATE_PRICE_RULE_FAILED,
  UPDATE_PRICE_RULE_SUCCESS,
  GET_PRICE_RULE_DETAIL,
  GET_PRICE_RULE_DETAIL_FAILED,
  GET_PRICE_RULE_DETAIL_SUCCESS,
  ENABLE_ALL_PRICE_RULES_ITEMS,
  DISABLE_ALL_PRICE_RULES_ITEMS,
  TOGGLE_PRICE_RULES_ITEM,
  GET_PRICE_RULE_OPTIONS,
  GET_PRICE_RULE_OPTIONS_SUCCESS,
  GET_PRICE_RULE_OPTIONS_FAILED,
  ADD_DURATION_PRICE_RULE,
  EDIT_DURATION_PRICE_RULE,
  DELETE_DURATION_PRICE_RULE,
  CLEAR_DURATION_PRICE_RULE,
  GET_PRICE_RULE_CONTRACT_LOCATIONS,
  GET_PRICE_RULE_CONTRACT_LOCATIONS_FAILED,
  GET_PRICE_RULE_CONTRACT_LOCATIONS_SUCCESS,
  CONTRACT_IS_UPDATING,
  DELETE_PRICE_RULE,
  DELETE_PRICE_RULE_FAILED,
  DELETE_PRICE_RULE_SUCCESS,
  GET_PRICE_RULE_REGION_COUNTRIES,
  GET_PRICE_RULE_REGION_COUNTRIES_FAILED,
  GET_PRICE_RULE_REGION_COUNTRIES_SUCCESS,
  GET_PRICE_RULE_COUNTRIES_LOCATIONS,
  GET_PRICE_RULE_COUNTRIES_LOCATIONS_FAILED,
  GET_PRICE_RULE_COUNTRIES_LOCATIONS_SUCCESS,
  SET_PRICE_RULE_CUSTOM_BOX_UPDATING,
} from "./../actions/priceRules.action";

const INITIAL_STATE = {
  priceRules: [],
  selectedPriceRule: {},
  optionsList: [],
  durationBaseRules: [],
  contractIsUpdating: false,
  regionIsUpdating: false,
  pickupIsUpdating: false,
  isOptionLoading: false,
  isLoading: false,
  isError: false,
  message: "",
};

export const PriceRulesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_PRICE_RULE:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: "",
      };
    case CREATE_PRICE_RULE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        priceRules: state.priceRules.concat(action.payload),
        message: action.message,
      };
    case CREATE_PRICE_RULE_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message,
      };
    case UPDATE_PRICE_RULE:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: "",
      };
    case UPDATE_PRICE_RULE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        priceRules: state.priceRules.map((item) => {
          return item.id === action.payload.id ? action.payload : item;
        }),
        message: action.message,
      };
    case UPDATE_PRICE_RULE_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message,
      };
    case GET_ALL_PRICE_RULES:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: "",
      };
    case GET_ALL_PRICE_RULES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        priceRules: action.payload,
        message: action.message,
      };
    case GET_ALL_PRICE_RULES_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message,
      };
    case GET_PRICE_RULE_DETAIL:
      return {
        ...state,
        isLoading: false,
        isError: false,
        message: "",
      };
    case GET_PRICE_RULE_DETAIL_SUCCESS:
      return {
        ...state,
        isOptionLoading: false,
        isLoading: false,
        selectedPriceRule: action.payload,
        optionsList: action.optionList,
        durationBaseRules: action.payload.durations.map((val) => {
          return {
            ...val,
            margin: val.margin_multiplier,
            duration_id: val.id,
            daysFrom: val.from_days,
            daysTo: val.to_days,
          };
        }),
        message: action.message,
      };
    case GET_PRICE_RULE_DETAIL_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message,
      };
    case TOGGLE_PRICE_RULES_ITEM:
      return {
        ...state,
        optionsList: {
          ...state.optionsList,
          [action.name]:
            state.optionsList &&
            state.optionsList[action.name].map((v) => {
              if (
                v.id === action.id &&
                v.id !== undefined &&
                action.id !== undefined
              ) {
                return {
                  ...v,
                  is_selected: !v.is_selected,
                };
              }
              return v;
            }),
        },
      };

    case ENABLE_ALL_PRICE_RULES_ITEMS:
      return {
        ...state,
        optionsList: {
          ...state.optionsList,
          [action.name]: state.optionsList[action.name].map((v) => {
            return {
              ...v,
              is_selected: true,
            };
          }),
        },
      };
    case DISABLE_ALL_PRICE_RULES_ITEMS:
      return {
        ...state,
        optionsList: {
          ...state.optionsList,
          [action.name]: state.optionsList[action.name].map((v) => {
            return {
              ...v,
              is_selected: false,
            };
          }),
        },
      };
    case GET_PRICE_RULE_OPTIONS:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: "",
      };
    case GET_PRICE_RULE_OPTIONS_SUCCESS:
      return {
        ...state,
        optionsList: action.payload,
        isLoading: false,
        isError: false,
        message: action.message,
      };
    case GET_PRICE_RULE_OPTIONS_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        message: action.message,
      };
    case ADD_DURATION_PRICE_RULE:
      return {
        ...state,
        durationBaseRules: state.durationBaseRules.concat(action.payload),
      };
    case EDIT_DURATION_PRICE_RULE:
      return {
        ...state,
        durationBaseRules: state.durationBaseRules.map((val) =>
          val.id === action.payload.id ? action.payload : val
        ),
      };
    case DELETE_DURATION_PRICE_RULE:
      return {
        ...state,
        durationBaseRules: state.durationBaseRules.filter(
          (val) => val.id !== action.payload
        ),
      };
    case CLEAR_DURATION_PRICE_RULE:
      return {
        ...state,
        durationBaseRules: [],
      };
    case GET_PRICE_RULE_CONTRACT_LOCATIONS:
      return {
        ...state,
        isOptionLoading: true,
        isError: false,
        message: "",
      };
    case GET_PRICE_RULE_CONTRACT_LOCATIONS_SUCCESS:
      return {
        ...state,
        isOptionLoading: false,
        optionsList: {
          ...state.optionsList,
          ...action.payload,
        },
        message: action.message,
      };

    case GET_PRICE_RULE_CONTRACT_LOCATIONS_FAILED:
      return {
        ...state,
        isOptionLoading: false,
        isError: true,
        message: action.message,
      };
    case GET_PRICE_RULE_REGION_COUNTRIES:
      return {
        ...state,
        isOptionLoading: true,
        isError: false,
        message: "",
      };
    case GET_PRICE_RULE_REGION_COUNTRIES_SUCCESS:
      let pickup_countries = { pickup_countries: action.payload };
      return {
        ...state,
        isOptionLoading: false,
        optionsList: {
          ...state.optionsList,
          ...pickup_countries,
        },
        message: action.message,
      };

    case GET_PRICE_RULE_REGION_COUNTRIES_FAILED:
      return {
        ...state,
        isOptionLoading: false,
        isError: true,
        message: action.message,
      };
    case GET_PRICE_RULE_COUNTRIES_LOCATIONS:
      return {
        ...state,
        isOptionLoading: true,
        isError: false,
        message: "",
      };
    case GET_PRICE_RULE_COUNTRIES_LOCATIONS_SUCCESS:
      let location = { location: action.payload };
      return {
        ...state,
        isOptionLoading: false,
        optionsList: {
          ...state.optionsList,
          ...location,
        },
        message: action.message,
      };

    case GET_PRICE_RULE_COUNTRIES_LOCATIONS_FAILED:
      return {
        ...state,
        isOptionLoading: false,
        isError: true,
        message: action.message,
      };

    case CONTRACT_IS_UPDATING:
      return {
        ...state,
        contractIsUpdating: action.payload,
      };
    case SET_PRICE_RULE_CUSTOM_BOX_UPDATING:
      return {
        ...state,
        [action.name]: action.payload,
      };
    case DELETE_PRICE_RULE:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: "",
      };
    case DELETE_PRICE_RULE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        priceRules: state.priceRules.filter((val) => val.id != action.id),
        message: action.message,
      };
    case DELETE_PRICE_RULE_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message,
      };
    default:
      return state;
  }
};
