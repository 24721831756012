import { ofType } from "redux-observable";
import { mapTo, delay } from "rxjs/operators";

import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT,
  SET_CURRENCIES,
  SET_CUURENCIES_SUCCESS,
  SET_CUURENCIES_FAILED,
} from "./../actions/auth.action";
import {
  SEARCH_AVAILABLE_CARS,
  SEARCH_AVAILABLE_CARS_FAILED,
  SEARCH_AVAILABLE_CARS_SUCCESS,
} from "./../actions/availableCars.action";
import {
  SEARCH_BOOKINGS,
  SEARCH_BOOKINGS_FAILED,
  SEARCH_BOOKINGS_SUCCESS,
  CANCEL_BOOKINGS,
  CANCEL_BOOKINGS_FAILED,
  CANCEL_BOOKINGS_SUCCESS,
  SET_REMINDER,
  SET_REMINDER_FAILED,
  SEND_CHECKIN_EMAIL,
  SEND_CHECKIN_EMAIL_SUCCESS,
  SEND_CHECKIN_EMAIL_FAILED,
  SEND_CHECKOUT_EMAIL,
  SEND_CHECKOUT_EMAIL_SUCCESS,
  SEND_CHECKOUT_EMAIL_FAILED,
  SET_REMINDER_SUCCESS,
} from "./../actions/bookings.action";
import {
  MAKE_CAR_RESERVATION,
  MAKE_CAR_RESERVATION_FAILED,
  MAKE_CAR_RESERVATION_SUCCESS,
} from "./../actions/carReservation.action";
import {
  CREATE_CONTRACT,
  CREATE_CONTRACT_FAILED,
  CREATE_CONTRACT_SUCCESS,
  GET_CONTRACT_DETAIL,
  GET_CONTRACT_DETAIL_FAILED,
  GET_CONTRACT_DETAIL_SUCCESS,
  UPDATE_CONTRACT,
  UPDATE_CONTRACT_FAILED,
  UPDATE_CONTRACT_SUCCESS,
} from "./../actions/contracts.action";
import {
  CREATE_EXTRAS,
  CREATE_EXTRAS_FAILED,
  CREATE_EXTRAS_SUCCESS,
  UPDATE_EXTRAS,
  UPDATE_EXTRAS_SUCCESS,
  UPDATE_EXTRAS_FAILED,
  DELETE_EXTRAS,
  DELETE_EXTRAS_FAILED,
  DELETE_EXTRAS_SUCCESS,
  GET_EXTRAS_DETAIL,
  GET_EXTRAS_DETAIL_FAILED,
  GET_EXTRAS_DETAIL_SUCCESS,
} from "./../actions/extras.action";
import {
  CREATE_PRICE_RULE,
  CREATE_PRICE_RULE_FAILED,
  CREATE_PRICE_RULE_SUCCESS,
  UPDATE_PRICE_RULE,
  UPDATE_PRICE_RULE_FAILED,
  UPDATE_PRICE_RULE_SUCCESS,
  DELETE_PRICE_RULE,
  DELETE_PRICE_RULE_FAILED,
  DELETE_PRICE_RULE_SUCCESS,
} from "./../actions/priceRules.action";
import {
  SET_CUSTOMER_STATUS,
  SET_CUSTOMER_STATUS_FAILED,
  SET_CUSTOMER_STATUS_SUCCESS,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILED,
} from "./../actions/customer.action";
import {
  GET_INTEGRATIONS,
  GET_INTEGRATIONS_FAILED,
  GET_INTEGRATIONS_SUCCESS,
  GET_SUPPLIERS,
  GET_SUPPLIERS_FAILED,
  GET_SUPPLIERS_SUCCESS,
} from "./../actions/supply.action";
import { RESET_STATE } from "./../reducers/app.reducer";

export class AppEpic {
  static resetState = (action$) =>
    action$.pipe(
      ofType(
        LOGIN,
        SEARCH_AVAILABLE_CARS,
        SEARCH_BOOKINGS,
        MAKE_CAR_RESERVATION,
        CREATE_CONTRACT,
        GET_CONTRACT_DETAIL,
        UPDATE_CONTRACT,
        CREATE_EXTRAS,
        UPDATE_EXTRAS,
        DELETE_EXTRAS,
        GET_EXTRAS_DETAIL,
        CREATE_PRICE_RULE,
        UPDATE_PRICE_RULE,
        DELETE_PRICE_RULE,
        LOGIN_SUCCESS,
        SET_CUSTOMER_STATUS,
        LOGIN_FAILED,
        LOGOUT,
        CANCEL_BOOKINGS,
        SET_REMINDER,
        SET_REMINDER_FAILED,
        SET_REMINDER_SUCCESS,
        SEND_CHECKIN_EMAIL,
        SEND_CHECKIN_EMAIL_SUCCESS,
        SEND_CHECKIN_EMAIL_FAILED,
        SEND_CHECKOUT_EMAIL,
        SEND_CHECKOUT_EMAIL_FAILED,
        SEND_CHECKOUT_EMAIL_SUCCESS,
        SEARCH_AVAILABLE_CARS_FAILED,
        SEARCH_AVAILABLE_CARS_SUCCESS,
        SEARCH_BOOKINGS_SUCCESS,
        SEARCH_BOOKINGS_FAILED,
        MAKE_CAR_RESERVATION_SUCCESS,
        MAKE_CAR_RESERVATION_FAILED,
        CREATE_CONTRACT_SUCCESS,
        CREATE_CONTRACT_FAILED,
        GET_CONTRACT_DETAIL_FAILED,
        GET_CONTRACT_DETAIL_SUCCESS,
        UPDATE_CONTRACT_FAILED,
        UPDATE_CONTRACT_SUCCESS,
        CREATE_EXTRAS_SUCCESS,
        CREATE_EXTRAS_FAILED,
        UPDATE_EXTRAS_FAILED,
        UPDATE_EXTRAS_SUCCESS,
        DELETE_EXTRAS_SUCCESS,
        DELETE_EXTRAS_FAILED,
        GET_EXTRAS_DETAIL_SUCCESS,
        GET_EXTRAS_DETAIL_FAILED,
        CREATE_PRICE_RULE_SUCCESS,
        CREATE_PRICE_RULE_FAILED,
        UPDATE_PRICE_RULE_FAILED,
        UPDATE_PRICE_RULE_SUCCESS,
        DELETE_PRICE_RULE_SUCCESS,
        DELETE_PRICE_RULE_FAILED,
        CANCEL_BOOKINGS_FAILED,
        CANCEL_BOOKINGS_SUCCESS,
        SET_CUSTOMER_STATUS_FAILED,
        SET_CUSTOMER_STATUS_SUCCESS,
        GET_SUPPLIERS_FAILED,
        GET_SUPPLIERS_SUCCESS,
        GET_INTEGRATIONS_FAILED,
        GET_INTEGRATIONS_SUCCESS,
        UPDATE_CUSTOMER,
        UPDATE_CUSTOMER_SUCCESS,
        UPDATE_CUSTOMER_FAILED,
        SET_CURRENCIES,
        SET_CUURENCIES_SUCCESS,
        SET_CUURENCIES_FAILED
      ),
      mapTo({ type: RESET_STATE }),
      delay(1500)
    );
}
