import {
  SEARCH_AVAILABLE_CARS,
  SEARCH_AVAILABLE_CARS_FAILED,
  SEARCH_AVAILABLE_CARS_SUCCESS,
  SET_AVAILABLE_CARS_FILTER,
  CLEAR_FILTERS,
  SET_CURRENT_STEP_ID,
  SET_WORKING_STEP_ID,
  GET_SEARCH_VEHICLE_FILTER_SUCCESS,
} from "../actions/availableCars.action";

const INITIAL_STATE = {
  pickUpStation: "",
  dropOffStation: "",
  workingStepId: 1,
  currentStepId: 1,
  startDate: null,
  endDate: null,
  acriss_code: "",
  supplier: "",
  driveResidence: "",
  childSeats: "",
  car: "",
  maxExcessLiability: "",
  bankCards: "",
  currency: "",
  maxDeposit: "",
  maxMilleage: "",
  availableCars: [],
  pagination: {},
  isLoading: false,
  isError: false,
  message: "",
};

export const AvailableCarsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SEARCH_AVAILABLE_CARS:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: "",
        availableCars: [],
      };
    case SEARCH_AVAILABLE_CARS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        availableCars: action.payload,
        pagination: action.pagination,
        message: action.message,
      };
    case SEARCH_AVAILABLE_CARS_FAILED:
      return {
        ...state,
        isLoading: false,
        message: action.message,
        isError: true,
      };
    case SET_AVAILABLE_CARS_FILTER:
      return {
        ...state,
        [action.key]: action.value,
      };
    case SET_WORKING_STEP_ID:
      return {
        ...state,
        workingStepId: action.payload,
      };
    case SET_CURRENT_STEP_ID:
      return {
        ...state,
        currentStepId: action.payload,
      };
    case GET_SEARCH_VEHICLE_FILTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        moreOptions: action.payload,
        message: action.message,
      };
    case CLEAR_FILTERS:
      return INITIAL_STATE;
    default:
      return state;
  }
};
