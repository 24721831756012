import { Observable } from "rxjs";
import { ofType } from "redux-observable";
import { switchMap } from "rxjs/operators";

import {
  GET_INTEGRATIONS,
  GET_INTEGRATIONS_FAILED,
  GET_INTEGRATIONS_SUCCESS,
  GET_SUPPLIERS,
  GET_SUPPLIERS_FAILED,
  GET_SUPPLIERS_SUCCESS,
} from "../actions/supply.action";
import {
  BASE,
  GET_INTEGRATIONS_API,
  GET_SUPPLIERS_API,
} from "./../../config/api";
import { axiosClient } from "./auth.epic";

export class SupplyEpic {
  static getIntegrations = (action$) =>
    action$.pipe(
      ofType(GET_INTEGRATIONS),
      switchMap(() => {
        return new Observable(async (observer) => {
          try {
            const result = await axiosClient.get(`${GET_INTEGRATIONS_API}`);
            const { data } = result;
            if (data.success) {
              observer.next({
                type: GET_INTEGRATIONS_SUCCESS,
                payload: data.data,
                message: "Get Integrations Success",
              });
            } else {
              observer.next({
                type: GET_INTEGRATIONS_FAILED,
                message: "Get Ingtegrations Failed",
              });
            }
          } catch (err) {
            observer.next({
              type: GET_INTEGRATIONS_FAILED,
              message: "Error in getting Integrations",
            });
          }
        });
      })
    );
  static getSuppliers = (action$) =>
    action$.pipe(
      ofType(GET_SUPPLIERS),
      switchMap(() => {
        return new Observable(async (observer) => {
          try {
            const result = await axiosClient.get(`${GET_SUPPLIERS_API}`);
            const { data } = result;
            if (data.success) {
              observer.next({
                type: GET_SUPPLIERS_SUCCESS,
                payload: data.data,
                message: "Get Integrations Success",
              });
            } else {
              observer.next({
                type: GET_SUPPLIERS_FAILED,
                message: "Get Ingtegrations Failed",
              });
            }
          } catch (err) {
            observer.next({
              type: GET_SUPPLIERS_FAILED,
              message: "Error in getting Integrations",
            });
          }
        });
      })
    );
}
