import {
  CREATE_EXTRAS,
  CREATE_EXTRAS_FAILED,
  CREATE_EXTRAS_SUCCESS,
  UPDATE_EXTRAS,
  UPDATE_EXTRAS_SUCCESS,
  UPDATE_EXTRAS_FAILED,
  DELETE_EXTRAS,
  DELETE_EXTRAS_FAILED,
  DELETE_EXTRAS_SUCCESS,
  GET_ALL_EXTRAS,
  GET_ALL_EXTRAS_FAILED,
  GET_ALL_EXTRAS_SUCCESS,
  GET_EXTRAS_DETAIL,
  GET_EXTRAS_DETAIL_FAILED,
  GET_EXTRAS_DETAIL_SUCCESS,
  GET_EXTRAS_OPTIONS,
  GET_EXTRAS_OPTIONS_FAILED,
  GET_EXTRAS_OPTIONS_SUCCESS,
  TOGGLE_EXTRAS_ITEM,
  ENABLE_ALL_EXTRAS_ITEMS,
  DISABLE_ALL_EXTRAS_ITEMS,
  GET_EXTRAS_CONTRACT_LOCATIONS,
  GET_EXTRAS_CONTRACT_LOCATIONS_FAILED,
  GET_EXTRAS_CONTRACT_LOCATIONS_SUCCESS,
  SET_EXTRAS_CUSTOM_BOX_UPDATING,
  CONTRACT_IS_UPDATING,
  GET_EXTRAS_REGION_COUNTRIES,
  GET_EXTRAS_REGION_COUNTRIES_SUCCESS,
  GET_EXTRAS_REGION_COUNTRIES_FAILED,
  GET_EXTRAS_COUNTRIES_LOCATIONS,
  GET_EXTRAS_COUNTRIES_LOCATIONS_FAILED,
  GET_EXTRAS_COUNTRIES_LOCATIONS_SUCCESS
} from "./../actions/extras.action";

const INITIAL_STATE = {
  extras: [],
  selectedExtra: {},
  optionsList: [],
  isOptionLoading: false,
  contractIsUpdating: false,
  regionIsUpdating: false,
  pickupIsUpdating: false,
  isOptionLoading: false,
  isLoading: false,
  isError: false,
  message: ""
};

export const ExtrasReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_EXTRAS:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: ""
      };
    case CREATE_EXTRAS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        extras: state.extras.concat(action.payload),
        message: action.message
      };
    case CREATE_EXTRAS_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message
      };
    case UPDATE_EXTRAS:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: ""
      };
    case UPDATE_EXTRAS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        extras: state.extras.map(item => {
          return item.id === action.payload.id ? action.payload : item;
        }),
        message: action.message
      };
    case UPDATE_EXTRAS_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message
      };
    case GET_ALL_EXTRAS:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: ""
      };
    case GET_ALL_EXTRAS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        extras: action.payload,
        message: action.message
      };
    case GET_ALL_EXTRAS_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message
      };
    case GET_EXTRAS_DETAIL:
      return {
        ...state,
        isLoading: false,
        isError: false,
        message: ""
      };
    case GET_EXTRAS_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedExtra: action.payload,
        optionsList: { meta: state.optionsList.meta, ...action.optionList },
        message: action.message
      };
    case GET_EXTRAS_DETAIL_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message
      };
    case TOGGLE_EXTRAS_ITEM:
      return {
        ...state,
        optionsList: {
          ...state.optionsList,
          [action.name]:
            state.optionsList &&
            state.optionsList[action.name].map(v => {
              if (v.id === action.id) {
                return {
                  ...v,
                  is_selected: !v.is_selected
                };
              }
              return v;
            })
        }
      };

    case ENABLE_ALL_EXTRAS_ITEMS:
      return {
        ...state,
        optionsList: {
          ...state.optionsList,
          [action.name]: state.optionsList[action.name].map(v => {
            return {
              ...v,
              is_selected: true
            };
          })
        }
      };
    case DISABLE_ALL_EXTRAS_ITEMS:
      return {
        ...state,
        optionsList: {
          ...state.optionsList,
          [action.name]: state.optionsList[action.name].map(v => {
            return {
              ...v,
              is_selected: false
            };
          })
        }
      };
    case GET_EXTRAS_OPTIONS:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: ""
      };
    case GET_EXTRAS_OPTIONS_SUCCESS:
      return {
        ...state,
        optionsList: action.payload,
        isLoading: false,
        isError: false,
        message: action.message
      };
    case GET_EXTRAS_OPTIONS_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        message: action.message
      };

    case DELETE_EXTRAS:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: ""
      };
    case DELETE_EXTRAS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        extras: state.extras.filter(val => val.id != action.id),
        message: action.message
      };
    case DELETE_EXTRAS_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message
      };
    case GET_EXTRAS_CONTRACT_LOCATIONS:
      return {
        ...state,
        isOptionLoading: true,
        isError: false,
        message: ""
      };
    case GET_EXTRAS_CONTRACT_LOCATIONS_SUCCESS:
      return {
        ...state,
        isOptionLoading: false,
        optionsList: {
          ...state.optionsList,
          ...action.payload
        },
        message: action.message
      };

    case GET_EXTRAS_CONTRACT_LOCATIONS_FAILED:
      return {
        ...state,
        isOptionLoading: false,
        isError: true,
        message: action.message
      };
    case GET_EXTRAS_REGION_COUNTRIES:
      return {
        ...state,
        isOptionLoading: true,
        isError: false,
        message: ""
      };
    case GET_EXTRAS_REGION_COUNTRIES_SUCCESS:
      let pickup_countries = { pickup_countries: action.payload };
      return {
        ...state,
        isOptionLoading: false,
        optionsList: {
          ...state.optionsList,
          ...pickup_countries
        },
        message: action.message
      };

    case GET_EXTRAS_REGION_COUNTRIES_FAILED:
      return {
        ...state,
        isOptionLoading: false,
        isError: true,
        message: action.message
      };
    case GET_EXTRAS_COUNTRIES_LOCATIONS:
      return {
        ...state,
        isOptionLoading: true,
        isError: false,
        message: ""
      };
    case GET_EXTRAS_COUNTRIES_LOCATIONS_SUCCESS:
      let location = { location: action.payload };
      return {
        ...state,
        isOptionLoading: false,
        optionsList: {
          ...state.optionsList,
          ...location
        },
        message: action.message
      };

    case GET_EXTRAS_COUNTRIES_LOCATIONS_FAILED:
      return {
        ...state,
        isOptionLoading: false,
        isError: true,
        message: action.message
      };
    case CONTRACT_IS_UPDATING:
      return {
        ...state,
        contractIsUpdating: action.payload
      };
    case SET_EXTRAS_CUSTOM_BOX_UPDATING:
      return {
        ...state,
        [action.name]: action.payload
      };
    default:
      return state;
  }
};
