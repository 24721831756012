import { Observable } from "rxjs";
import { ofType } from "redux-observable";
import { switchMap } from "rxjs/operators";
import _ from "lodash";

import {
  GET_ALL_PRICE_RULES,
  GET_ALL_PRICE_RULES_FAILED,
  GET_ALL_PRICE_RULES_SUCCESS,
  CREATE_PRICE_RULE,
  CREATE_PRICE_RULE_FAILED,
  CREATE_PRICE_RULE_SUCCESS,
  UPDATE_PRICE_RULE,
  UPDATE_PRICE_RULE_FAILED,
  UPDATE_PRICE_RULE_SUCCESS,
  GET_PRICE_RULE_DETAIL,
  GET_PRICE_RULE_DETAIL_FAILED,
  GET_PRICE_RULE_DETAIL_SUCCESS,
  GET_PRICE_RULE_OPTIONS,
  GET_PRICE_RULE_OPTIONS_FAILED,
  GET_PRICE_RULE_OPTIONS_SUCCESS,
  GET_PRICE_RULE_CONTRACT_LOCATIONS,
  GET_PRICE_RULE_CONTRACT_LOCATIONS_FAILED,
  GET_PRICE_RULE_CONTRACT_LOCATIONS_SUCCESS,
  DELETE_PRICE_RULE,
  DELETE_PRICE_RULE_FAILED,
  DELETE_PRICE_RULE_SUCCESS,
  GET_PRICE_RULE_REGION_COUNTRIES,
  GET_PRICE_RULE_REGION_COUNTRIES_FAILED,
  GET_PRICE_RULE_REGION_COUNTRIES_SUCCESS,
  GET_PRICE_RULE_COUNTRIES_LOCATIONS,
  GET_PRICE_RULE_COUNTRIES_LOCATIONS_SUCCESS,
  GET_PRICE_RULE_COUNTRIES_LOCATIONS_FAILED,
} from "../actions/priceRules.action";
import {
  BASE,
  CREATE_PRICE_RULE_API,
  UPDATE_PRICE_RULE_API,
  GET_ALL_PRICE_RULES_API,
  GET_PRICE_RULE_DETAIL_API,
  GET_PRICE_RULE_OPTIONS_API,
  DELETE_PRICE_RULE_API,
  GET_PRICE_RULE_CONTRACT_LOCATIONS_API,
  GET_PRICE_RULE_REGION_COUNTRIES_API,
  GET_PRICE_RULE_COUNTRIES_LOCATION_API,
} from "./../../config/api";
import { axiosClient } from "./auth.epic";

export class PriceRulesEpic {
  static createPriceRules = (action$) =>
    action$.pipe(
      ofType(CREATE_PRICE_RULE),
      switchMap(({ payload, setSubmiting, hideModal }) => {
        return new Observable(async (observer) => {
          try {
            const result = await axiosClient.post(
              `${CREATE_PRICE_RULE_API}`,
              payload
            );
            const { data } = result;
            if (data.success) {
              observer.next({
                type: CREATE_PRICE_RULE_SUCCESS,
                payload: data.data,
                message: "Price rule created successful",
              });
              setSubmiting(false);
              hideModal();
            } else {
              observer.next({
                type: CREATE_PRICE_RULE_FAILED,
                message: "Price rule create failed",
              });
              setSubmiting(false);
            }
          } catch (err) {
            observer.next({
              type: CREATE_PRICE_RULE_FAILED,
              message: "Error in creating price rules",
            });
            setSubmiting(false);
          }
        });
      })
    );
  static updatePriceRule = (action$) =>
    action$.pipe(
      ofType(UPDATE_PRICE_RULE),
      switchMap(({ payload, setSubmiting, hideModal }) => {
        return new Observable(async (observer) => {
          try {
            const result = await axiosClient.post(
              `${UPDATE_PRICE_RULE_API}`,
              payload
            );
            const { data } = result;
            if (data.success) {
              observer.next({
                type: UPDATE_PRICE_RULE_SUCCESS,
                payload: data.data,
                message: "Price rule Updated Successful",
              });
              setSubmiting(false);
              hideModal();
            } else {
              observer.next({
                type: UPDATE_PRICE_RULE_FAILED,
                message: "Price rule update failed",
              });
              setSubmiting(false);
            }
          } catch (err) {
            observer.next({
              type: UPDATE_PRICE_RULE_FAILED,
              message: "Error in updating price rule",
            });
            setSubmiting(false);
          }
        });
      })
    );
  static deletePriceRule = (action$) =>
    action$.pipe(
      ofType(DELETE_PRICE_RULE),
      switchMap(({ id }) => {
        return new Observable(async (observer) => {
          try {
            const result = await axiosClient.delete(
              `${DELETE_PRICE_RULE_API}${id}`
            );
            const { data } = result;
            if (data.success) {
              observer.next({
                type: DELETE_PRICE_RULE_SUCCESS,
                id: data.data.id,
                message: "Price rule Deleted Successful",
              });
            } else {
              observer.next({
                type: DELETE_PRICE_RULE_FAILED,
                message: "Price rule delete failed",
              });
            }
          } catch (err) {
            observer.next({
              type: DELETE_PRICE_RULE_FAILED,
              message: "Error in deleting price rule",
            });
          }
        });
      })
    );
  static getAllPriceRules = (action$) =>
    action$.pipe(
      ofType(GET_ALL_PRICE_RULES),
      switchMap(() => {
        return new Observable(async (observer) => {
          try {
            const result = await axiosClient.get(`${GET_ALL_PRICE_RULES_API}`);
            const { data } = result;
            if (data.success) {
              observer.next({
                type: GET_ALL_PRICE_RULES_SUCCESS,
                payload: data.data,
                message: "Get all price rules successful",
              });
            } else {
              observer.next({
                type: GET_ALL_PRICE_RULES_FAILED,
                message: "Get all price rules failed",
              });
            }
          } catch (err) {
            observer.next({
              type: GET_ALL_PRICE_RULES_FAILED,
              message: "Error in getting all price rules",
            });
          }
        });
      })
    );
  static getPriceRuleDetail = (action$) =>
    action$.pipe(
      ofType(GET_PRICE_RULE_DETAIL),
      switchMap(({ id, showModal, filter }) => {
        return new Observable(async (observer) => {
          try {
            const result = await axiosClient.get(
              `${GET_PRICE_RULE_DETAIL_API}${id}`
            );
            const {
              data: { data: optionList },
            } = await axiosClient.get(
              `${GET_PRICE_RULE_OPTIONS_API}?id=${id}&filters=${filter}`
            );

            const contract_locations = await axiosClient.post(
              `${GET_PRICE_RULE_COUNTRIES_LOCATION_API}`,
              {
                contract_id:
                  result.data &&
                  result.data.data.contracts.map((v) => v.contract_id),
                countries:
                  result &&
                  result.data.data.pickup_countries.map((v) => {
                    return {
                      code: v.code,
                    };
                  }),
              }
            );
            const contract_regions = await axiosClient.post(
              `${GET_PRICE_RULE_CONTRACT_LOCATIONS_API}`,
              {
                contracts:
                  result.data &&
                  result.data.data.contracts.map((v) => v.contract_id),
              }
            );

            const {
              data: { data: result_data },
            } = result;

            let contracts = optionList.contracts.map((val) => {
              let contractFound =
                result_data &&
                result_data.contracts.find((v) => v.contract_id === val.id);
              if (contractFound) {
                return {
                  ...val,
                  is_selected: true,
                  contract_id: contractFound.contract_id,
                };
              } else {
                return val;
              }
            });

            let platforms = optionList.platforms.map((val) => {
              let platformFound =
                result_data &&
                result_data.platforms.find((v) => v.platform_id === val.id);
              if (platformFound) {
                return {
                  ...val,
                  is_selected: true,
                  platform_id: platformFound.id,
                };
              } else {
                return val;
              }
            });

            let vehicles = optionList.vehicles.map((val) => {
              let VehicleFound =
                result_data &&
                result_data.vehicles.find((v) => v.car_group === val.id);
              if (VehicleFound) {
                return {
                  ...val,
                  is_selected: true,
                  car_group_id: VehicleFound.id,
                };
              } else {
                return val;
              }
            });

            let residence_countries =
              optionList &&
              optionList.contractLocations &&
              optionList.contractLocations.residence_countries.map((val) => {
                let CountryFound =
                  result_data &&
                  result_data.residence_countries.find(
                    (v) => v.country_id === val.id
                  );
                if (CountryFound) {
                  return {
                    ...val,
                    is_selected: true,
                    pricing_rule_country_id: CountryFound.id,
                  };
                } else {
                  return val;
                }
              });

            const { data } = result;
            if (data.success && contract_locations.status) {
              observer.next({
                type: GET_PRICE_RULE_DETAIL_SUCCESS,
                payload: data.data,
                optionList: {
                  contracts: contracts,
                  vehicles: vehicles,
                  platforms: platforms,
                  location: contract_locations.data.data.map((v) => {
                    if (
                      result_data.location.find(
                        (x) => x.supplier_location_id === v.supplier_location_id
                      )
                    ) {
                      return {
                        ...v,
                        name: v.label,
                        is_selected: true,
                      };
                    } else {
                      return {
                        ...v,
                        name: v.label,
                        is_selected: false,
                      };
                    }
                  }),
                  region: contract_regions.data.data.region.map((v) => {
                    if (result_data.region.find((x) => x.name === v.name)) {
                      return {
                        ...v,
                        is_selected: true,
                      };
                    } else {
                      return {
                        ...v,
                        is_selected: false,
                      };
                    }
                  }),

                  pickup_countries:
                    result_data &&
                    result_data.pickup_countries.map((v) => {
                      return {
                        ...v,
                        is_selected: true,
                      };
                    }),
                  residence_countries: residence_countries,
                },
                message: "Price rule options fetch successful",
              });
              showModal("editPriceRuleModal");
            } else {
              observer.next({
                type: GET_PRICE_RULE_DETAIL_FAILED,
                message: "Price rule detail fetch failed",
              });
            }
          } catch (err) {
            observer.next({
              type: GET_PRICE_RULE_DETAIL_FAILED,
              message: "Error in get price rule detail",
            });
          }
        });
      })
    );
  static getPriceRuleOptions = (action$) =>
    action$.pipe(
      ofType(GET_PRICE_RULE_OPTIONS),
      switchMap(({ filter }) => {
        return new Observable(async (observer) => {
          try {
            const result = await axiosClient.get(
              `${GET_PRICE_RULE_OPTIONS_API}?filters=${filter}`
            );
            const { data } = result;
            if (data.success) {
              observer.next({
                type: GET_PRICE_RULE_OPTIONS_SUCCESS,
                payload: data.data,
                message: "Price rule options fetched successful",
              });
            } else {
              observer.next({
                type: GET_PRICE_RULE_OPTIONS_FAILED,
                message: "Price rule options fetch failed",
              });
            }
          } catch (err) {
            observer.next({
              type: GET_PRICE_RULE_OPTIONS_FAILED,
              message: "Error in get Price rule options",
            });
          }
        });
      })
    );
  static getPriceRuleContractLocations = (action$, state$) =>
    action$.pipe(
      ofType(GET_PRICE_RULE_CONTRACT_LOCATIONS),
      switchMap(({ payload }) => {
        return new Observable(async (observer) => {
          try {
            const result = await axiosClient.post(
              `${GET_PRICE_RULE_CONTRACT_LOCATIONS_API}`,
              payload
            );
            const { data } = result;

            if (data.success) {
              const region = data.data.region.map((v) => {
                if (
                  state$.value.PriceRules.optionsList.region.find(
                    (x) => x.name === v.name && x.is_selected === true
                  )
                ) {
                  return {
                    ...v,
                    is_selected: true,
                  };
                } else {
                  return {
                    ...v,
                    is_selected: false,
                  };
                }
              });

              const residence_countries = data.data.residence_countries.map(
                (val) => {
                  let CountryFound = state$.value.PriceRules.optionsList.residence_countries.find(
                    (v) => v.country_id === val.id && v.is_selected === true
                  );
                  if (CountryFound) {
                    return {
                      ...val,
                      is_selected: true,
                      pricing_rule_country_id: CountryFound.id,
                    };
                  } else {
                    return val;
                  }
                }
              );

              observer.next({
                type: GET_PRICE_RULE_CONTRACT_LOCATIONS_SUCCESS,
                payload: {
                  region,
                  residence_countries,
                },
                message: "Price rule contract locations fetched successful",
              });
            } else {
              observer.next({
                type: GET_PRICE_RULE_CONTRACT_LOCATIONS_FAILED,
                message: "Price rule contract locations fetch failed",
              });
            }
          } catch (err) {
            observer.next({
              type: GET_PRICE_RULE_CONTRACT_LOCATIONS_FAILED,
              message: "Error in get Price rule contract locations",
            });
          }
        });
      })
    );
  static getPriceRuleRegionCountries = (action$, state$) =>
    action$.pipe(
      ofType(GET_PRICE_RULE_REGION_COUNTRIES),
      switchMap(({ payload }) => {
        return new Observable(async (observer) => {
          try {
            const result = await axiosClient.post(
              `${GET_PRICE_RULE_REGION_COUNTRIES_API}`,
              payload
            );

            const { data } = result;
            const state$_pickupCountries =
              state$.value.PriceRules.optionsList.pickup_countries;

            const pickup_countries = data.data.map((v) => {
              if (
                state$_pickupCountries.find(
                  (x) => x.code === v.code && x.is_selected === true
                )
              ) {
                return {
                  ...v,
                  is_selected: true,
                };
              } else {
                return {
                  ...v,
                  is_selected: false,
                };
              }
            });

            if (data.success) {
              observer.next({
                type: GET_PRICE_RULE_REGION_COUNTRIES_SUCCESS,
                payload: pickup_countries,
                message: "Price rule price rule countries fetched successful",
              });
            } else {
              observer.next({
                type: GET_PRICE_RULE_REGION_COUNTRIES_FAILED,
                message: "Price rule price rule countries fetch failed",
              });
            }
          } catch (err) {
            observer.next({
              type: GET_PRICE_RULE_REGION_COUNTRIES_FAILED,
              message: "Error in get Price rule price rule countries",
            });
          }
        });
      })
    );
  static getPriceRuleCountriesLocations = (action$, state$) =>
    action$.pipe(
      ofType(GET_PRICE_RULE_COUNTRIES_LOCATIONS),
      switchMap(({ payload }) => {
        return new Observable(async (observer) => {
          try {
            const result = await axiosClient.post(
              `${GET_PRICE_RULE_COUNTRIES_LOCATION_API}`,
              payload
            );
            const { data } = result;
            const state$_location =
              state$.value.PriceRules.optionsList.location;
            const locations = data.data.map((v) => {
              if (
                state$_location.find(
                  (x) =>
                    x.supplier_location_id === v.supplier_location_id &&
                    x.is_selected
                )
              ) {
                return {
                  ...v,
                  name: v.label,
                  is_selected: true,
                };
              } else {
                return {
                  ...v,
                  name: v.label,
                  is_selected: false,
                };
              }
            });

            if (data.success) {
              observer.next({
                type: GET_PRICE_RULE_COUNTRIES_LOCATIONS_SUCCESS,
                payload: locations,
                message:
                  "Price rule price rule countries locations fetched successful",
              });
            } else {
              observer.next({
                type: GET_PRICE_RULE_COUNTRIES_LOCATIONS_FAILED,
                message:
                  "Price rule price rule countries locations fetch failed",
              });
            }
          } catch (err) {
            observer.next({
              type: GET_PRICE_RULE_COUNTRIES_LOCATIONS_FAILED,
              message: "Error in get Price rule price rule countries locations",
            });
          }
        });
      })
    );
}
