import { Observable } from "rxjs";
import { ofType } from "redux-observable";
import { switchMap } from "rxjs/operators";
import _ from "lodash";

import {
  GET_COUPON_LIST,
  GET_COUPON_LIST_FAILED,
  GET_COUPON_LIST_SUCCESS,
  GET_META_INFO,
  GET_META_INFO_FAILED,
  GET_META_INFO_SUCCESS,
  GET_SUPPLIER_REGIONS,
  GET_SUPPLIER_REGIONS_FAILED,
  GET_SUPPLIER_REGIONS_SUCCESS,
  GET_REGION_COUNTRIES,
  GET_REGION_COUNTRIES_FAILED,
  GET_REGION_COUNTRIES_SUCCESS,
  GET_ALL_CONTRACTS,
  GET_ALL_CONTRACTS_FAILED,
  GET_ALL_CONTRACTS_SUCCESS,
  GET_SUPPLIER_LOCATIONS,
  GET_SUPPLIER_LOCATIONS_FAILED,
  GET_SUPPLIER_LOCATIONS_SUCCESS,
  CREATE_COUPON,
  CREATE_COUPON_FAILED,
  CREATE_COUPON_SUCCESS,
  GET_COUPON_DETAIL,
  GET_COUPON_DETAIL_FAILED,
  GET_COUPON_DETAIL_SUCCESS,
  UPDATE_COUPON,
  UPDATE_COUPON_FAILED,
  UPDATE_COUPON_SUCCESS,
  DELETE_COUPON,
  DELETE_COUPON_SUCCESS,
  DELETE_COUPON_FAILED,
} from "../actions/coupon.action";
import {
  BASE,
  GET_CONTRACT_META_INFO_API,
  GET_SUPPLIER_REGIONS_API,
  GET_SUPPLIER_LOCATIONS_API,
  GET_ALL_CONTRACTS_API,
  CREATE_COUPON_API,
  GET_COUPON_DETAIL_API,
  UPDATE_COUPON_API,
  GET_REGION_COUNTRIES_API,
  DELETE_COUPON_API,
  GET_COUPON_LIST_API,
} from "./../../config/api";
import { axiosClient } from "./auth.epic";

export class CouponEpic{
  static createCoupon = (action$) =>
    action$.pipe(
      ofType(CREATE_COUPON),
      switchMap(({ payload, setSubmiting, hideModal }) => {
        return new Observable(async (observer) => {
          try {
            const result = await axiosClient.post(
              `${CREATE_COUPON_API}`,
              payload
            );
            const { data } = result;
            if (data.success) {
              observer.next({
                type: CREATE_COUPON_SUCCESS,
                payload: data.data,
                message: "Coupon created successfully",
              });
              setSubmiting(false);
              hideModal();
             // window.location.reload();
            } else {
              observer.next({
                type: CREATE_COUPON_FAILED,
                message: "Create Coupon failed",
              });
              setSubmiting(false);
            }
          } catch (err) {
            observer.next({
              type: CREATE_COUPON_FAILED,
              message: "Error in creating Coupon",
            });
            setSubmiting(false);
          }
        });
      })
    );
    static getCouponList = (action$) =>
    action$.pipe(
      ofType(GET_COUPON_LIST),
      switchMap(() => {
        
        return new Observable(async (observer) => {
          try {
            const { data } = await axiosClient.get(
              `${BASE}${GET_COUPON_LIST_API}`,
            );
            
            if (data.success) {
              observer.next({
                type: GET_COUPON_LIST_SUCCESS,
                payload: data.data,
                message: "Coupon Fetched Successfully",
              });
            } else {
              observer.next({
                type: GET_COUPON_LIST_FAILED,
                message: "Something went wrong",
              });
            }
          } catch (err) {
            observer.next({
              type: GET_COUPON_LIST_FAILED,
              message: "Something went wrong",
            });
          }
        });
      })
    );
  static updateCoupon = (action$) =>
    action$.pipe(
      ofType(UPDATE_COUPON),
      switchMap(({ payload, setSubmiting, hideModal }) => {
        return new Observable(async (observer) => {
          try {
            const result = await axiosClient.post(
              `${UPDATE_COUPON_API}`,
              payload
            );
            const { data } = result;
            console.log(data);
            if (data.success) {
              observer.next({
                type: UPDATE_COUPON_SUCCESS,
                payload: data.data,
                message: "Contract successfully updated",
              });
              setSubmiting(false);
              hideModal();
            } else {
              observer.next({
                type: UPDATE_COUPON_FAILED,
                message: "Updating contract failed",
              });
              setSubmiting(false);
            }
          } catch (err) {
            observer.next({
              type: UPDATE_COUPON_FAILED,
              message: "Error in updating contract",
            });
            setSubmiting(false);
          }
        });
      })
    );
  static deleteCoupon = (action$) =>
    action$.pipe(
      ofType(DELETE_COUPON),
      switchMap(({ payload, hideModal }) => {
        return new Observable(async (observer) => {
          try {
            const result = await axiosClient.delete(
              `${DELETE_COUPON_API}/${payload.id}`
            );
            const { data } = result;
            if (data.success) {
              observer.next({
                type: DELETE_COUPON_SUCCESS,
                payload,
                message: data.message,
              });
              hideModal();
              //window.location.reload();
            } else {
              observer.next({
                type: DELETE_COUPON_FAILED,
                message: "Deleting coupon failed",
              });
            }
          } catch (err) {
            observer.next({
              type: DELETE_COUPON_FAILED,
              message: "Error in deleting coupon",
            });
          }
        });
      })
    );
  static getAllContracts = (action$) =>
    action$.pipe(
      ofType(GET_ALL_CONTRACTS),
      switchMap(() => {
        return new Observable(async (observer) => {
          try {
            const result = await axiosClient.get(`${GET_ALL_CONTRACTS_API}`);
            const { data } = result;
            if (data.success) {
              observer.next({
                type: GET_ALL_CONTRACTS_SUCCESS,
                payload: data.data,
                message: "Get all contracts successful",
              });
            } else {
              observer.next({
                type: GET_ALL_CONTRACTS_FAILED,
                message: "Get all contracts failed",
              });
            }
          } catch (err) {
            observer.next({
              type: GET_ALL_CONTRACTS_FAILED,
              message: "Error in getting all contracts",
            });
          }
        });
      })
    );
  static getCouponDetail = (action$) =>
    action$.pipe(
      ofType(GET_COUPON_DETAIL),
      switchMap(({ id, showModal }) => {
        return new Observable(async (observer) => {
          try {
            const { data } = await axiosClient.get(
              `${GET_COUPON_DETAIL_API}${id}`
            );
            // const { data: sup_reg_data } = await axiosClient.get(
            //   `${BASE}${GET_SUPPLIER_REGIONS_API}${
            //   data.data && data.data[0].supplier_id
            //   }`
            // );
            // const { data: region_countries } = await axiosClient.post(
            //   `${GET_REGION_COUNTRIES_API}`,
            //   {
            //     supplier_id: data.data && data.data[0].supplier_id,
            //     regions: data.data && data.data[0].regions.map((v) => v.region),
            //   }
            // );
            // let pick_up_countries = {
            //   pick_up: region_countries.data.countries.map((v) => {
            //     if (
            //       data.data[0].pickup_countries.find((x) => x.code === v.code)
            //     ) {
            //       return {
            //         ...v,
            //         is_selected: true,
            //       };
            //     }
            //     return v;
            //   }),
            // };

            // if (sup_reg_data.success) {
            //   observer.next({
            //     type: GET_SUPPLIER_REGIONS_SUCCESS,
            //     payload: {
            //       ...sup_reg_data.data,
            //       ...pick_up_countries,
            //     },
            //     message: "Get supplier regions info successful",
            //   });
            // }
            if (data.success) {
          
              observer.next({
                type: GET_COUPON_DETAIL_SUCCESS,
                payload: data.data,
                message: "Coupon details fetched successful",
              });
              showModal("editCouponModal");
            } else {
              observer.next({
                type: GET_COUPON_DETAIL_FAILED,
                message: "Coupon details fetch failed",
              });
            }
          } catch (err) {
            observer.next({
              type: GET_COUPON_DETAIL_FAILED,
              message: "Error in getting coupon details",
            });
          }
        });
      })
    );
  static getSupplierLocations = (action$) =>
    action$.pipe(
      ofType(GET_SUPPLIER_LOCATIONS),
      switchMap(({ supplier_id, payload }) => {
        return new Observable(async (observer) => {
          try {
            const result = await axiosClient.post(
              `${GET_SUPPLIER_LOCATIONS_API}${payload.supplier_id}`,
              payload
            );
            const { data } = result;
            if (data.success) {
              observer.next({
                type: GET_SUPPLIER_LOCATIONS_SUCCESS,
                payload: data.data,
                message: "Get supplier locations successful",
              });
            } else {
              observer.next({
                type: GET_SUPPLIER_LOCATIONS_FAILED,
                message: "Get supplier locations failed",
              });
            }
          } catch (err) {
            observer.next({
              type: GET_SUPPLIER_LOCATIONS_FAILED,
              message: "Error in getting supplier locations",
            });
          }
        });
      })
    );
  static getMetaInfo = (action$) =>
    action$.pipe(
      ofType(GET_META_INFO),
      switchMap(() => {
        return new Observable(async (observer) => {
          try {
            const result = await axiosClient.get(
              `${GET_CONTRACT_META_INFO_API}`
            );
            const { data } = result;
            if (data.success) {
              observer.next({
                type: GET_META_INFO_SUCCESS,
                payload: data.data,
                message: "Get contract meta-info successful",
              });
            } else {
              observer.next({
                type: GET_META_INFO_FAILED,
                message: "Get contract meta-info failed",
              });
            }
          } catch (err) {
            observer.next({
              type: GET_META_INFO_FAILED,
              message: "Error in getting contract meta-info",
            });
          }
        });
      })
    );
  static getSupplierRegions = (action$) =>
    action$.pipe(
      ofType(GET_SUPPLIER_REGIONS),
      switchMap(({ supplier_id }) => {
        return new Observable(async (observer) => {
          try {
            const result = await axiosClient.get(
              `${GET_SUPPLIER_REGIONS_API}${supplier_id}`
            );
            const { data } = result;
            if (data.success) {
              observer.next({
                type: GET_SUPPLIER_REGIONS_SUCCESS,
                payload: data.data,
                message: "Get supplier regions info successful",
              });
            } else {
              observer.next({
                type: GET_SUPPLIER_REGIONS_FAILED,
                message: "Get supplier regions info failed",
              });
            }
          } catch (err) {
            observer.next({
              type: GET_SUPPLIER_REGIONS_FAILED,
              message: "Error in getting supplier regions info",
            });
          }
        });
      })
    );
  static getRegionCountries = (action$) =>
    action$.pipe(
      ofType(GET_REGION_COUNTRIES),
      switchMap(({ payload }) => {
        return new Observable(async (observer) => {
          try {
            const result = await axiosClient.post(
              `${GET_REGION_COUNTRIES_API}`,
              payload
            );
            const { data } = result;
            if (data.success) {
              observer.next({
                type: GET_REGION_COUNTRIES_SUCCESS,
                payload: data.data.countries,
                message: "Get supplier regions info successful",
              });
            } else {
              observer.next({
                type: GET_REGION_COUNTRIES_FAILED,
                message: "Get supplier regions info failed",
              });
            }
          } catch (err) {
            observer.next({
              type: GET_REGION_COUNTRIES_FAILED,
              message: "Error in getting supplier regions info",
            });
          }
        });
      })
    );
}
