export const GET_PAYMENT_MODE = "GET_PAYMENT_MODE";
export const GET_PAYMENT_MODE_SUCCESS = "GET_PAYMENT_MODE_SUCCESS";
export const GET_PAYMENT_MODE_FAILED = "GET_PAYMENT_MODE_FAILED";

export const SET_PAYMENT_MODE = "SET_PAYMENT_MODE";
export const SET_PAYMENT_MODE_SUCCESS = "SET_PAYMENT_MODE_SUCCESS";
export const SET_PAYMENT_MODE_FAILED = "SET_PAYMENT_MODE_FAILED";



export const getPaymentMode = () => ({
  type: GET_PAYMENT_MODE,
});

export const setPaymentMode = (payload) => ({
  type: SET_PAYMENT_MODE,
  payload,
});
