import {
  LOGIN,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGOUT,
  SET_CURRENCIES,
  SET_CUURENCIES_SUCCESS,
  SET_CUURENCIES_FAILED,
} from "../actions/auth.action";

const INITIAL_STATE = {
  isUserLoggedIn: false,
  isError: false,
  isLoading: false,
  success: false,
  routeAccess: [],
  language: "English",
  message: {},
  user: {},
  currenciesList: [],
};

const AuthReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isLoading: true,
        isUserLoggedIn: false,
        isError: false,
        message: {},
        user: {},
        currenciesList: [],
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        routeAccess: action.routeAccess,
        success: action.success,
        user: action.user,
        message: action.message,
        isUserLoggedIn: true,
        token: action.token,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isUserLoggedIn: false,
        success: false,
        message: action.message,
      };
    case LOGOUT:
      return {
        isUserLoggedIn: false,
        isError: false,
        isLoading: false,
        success: false,
        routeAccess: [],
        language: "English",
        message: {},
        user: {},
      };

    case SET_CURRENCIES:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case SET_CUURENCIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currenciesList: action.currenciesList,
        message: action.message,
      };
    case SET_CUURENCIES_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message,
      };
    default:
      return state;
  }
};

export { AuthReducer };
