import {
  SELECT_CAR,
  SET_DRIVER_DETAIL,
  MAKE_CAR_RESERVATION,
  MAKE_CAR_RESERVATION_FAILED,
  MAKE_CAR_RESERVATION_SUCCESS,
  CLEAR_STEPPER_FORM,
  TOGGLE_EXTRAS,
  INCREMENT_EXTRAS_COUNT,
  DECREMENT_EXTRAS_COUNT,
} from "./../actions/carReservation.action";

const INITAL_STATE = {
  selectedCar: {},
  driverDetails: {
    first_name: "",
    last_name: "",
    title: "Mr",
    email: "",
    phone: "",
    country_code: "",
    driver_exp: null,
    email_checked: false,
  },
  paymentDetails: {},
  reservationDetails: {},
  isLoading: false,
  isError: false,
  message: "",
};

export const CarReservationReducer = (state = INITAL_STATE, action) => {
  switch (action.type) {
    case SELECT_CAR:
      return {
        ...state,
        selectedCar: {
          ...action.payload,
          extras: action.payload.extras.map((extra) => {
            if (extra.mandatory && extra.mandatory > 0) {
              return {
                ...extra,
                selected: true,
                count: 1,
              };
            }
            return {
              ...extra,
              selected: false,
              count: 1,
            };
          }),
        },
        isError: false,
        message: action.message,
      };
    case SET_DRIVER_DETAIL:
      return {
        ...state,
        driverDetails: {
          ...state.driverDetails,
          [action.key]: action.value,
        },
      };
    case MAKE_CAR_RESERVATION:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: "",
      };
    case MAKE_CAR_RESERVATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        reservationDetails: action.reservationVoucher,
        message: action.message,
      };

    case TOGGLE_EXTRAS:
      return {
        ...state,
        selectedCar: {
          ...state.selectedCar,
          extras: state.selectedCar.extras.map((extra) => {
            if (action.payload === extra.code) {
              return {
                ...extra,
                selected: !extra.selected,
              };
            }
            return extra;
          }),
        },
      };
    case INCREMENT_EXTRAS_COUNT:
      return {
        ...state,
        selectedCar: {
          ...state.selectedCar,
          extras: state.selectedCar.extras.map((extra) => {
            if (action.payload === extra.code) {
              return {
                ...extra,
                count: (extra.count += 1),
              };
            }
            return extra;
          }),
        },
      };
    case DECREMENT_EXTRAS_COUNT:
      return {
        ...state,
        selectedCar: {
          ...state.selectedCar,
          extras: state.selectedCar.extras.map((extra) => {
            if (action.payload === extra.code) {
              return {
                ...extra,
                count: (extra.count -= 1),
              };
            }
            return extra;
          }),
        },
      };
    case MAKE_CAR_RESERVATION_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message,
      };
    case CLEAR_STEPPER_FORM:
      return {
        ...state,
        driverDetails: {
          first_name: "",
          last_name: "",
          title: "Mr",
          email: "",
          phone: "",
          country_code: "",
          driver_exp: null,
        },
        paymentDetails: {},
      };
    default:
      return state;
  }
};
