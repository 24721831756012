import {
  SEARCH_BOOKINGS,
  SEARCH_BOOKINGS_FAILED,
  SEARCH_BOOKINGS_SUCCESS,
  CANCEL_BOOKINGS,
  CANCEL_BOOKINGS_FAILED,
  CANCEL_BOOKINGS_SUCCESS,
  SELECT_BOOKING,
  FETCH_BOOKING_DETAILS,
  FETCH_BOOKING_DETAILS_SUCCESS,
  FETCH_BOOKING_DETAILS_FAILED
} from "../actions/bookings.action";

const INITIAL_STATE = {
  allBookings: [],
  pagination: {},
  bookingDetails: {},
  isLoading: false,
  isError: false,
  message: "",
  all_suppliers: [
    { value: 1, label: "EuropCar" },
    { value: 2, label: "Hertz" },
    { value: 3, label: "GoldCar" },
    { value: 4, label: "EnterpriseHoldings" },
    { value: 5, label: "Alamo" },
    { value: 6, label: "National" },
    { value: 7, label: "Thrifty" },
    { value: 8, label: "Dollar" },
    { value: 9, label: "Ace" },
    { value: 10, label: "Surprice" },
    { value: 13, label: "Carwiz" },
  ],
};

export const BookingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SEARCH_BOOKINGS:
      return {
        ...state,
        isLoading: true,
        allBookings: [],
        isError: false,
        message: {},
      };
    case SEARCH_BOOKINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allBookings: action.bookings,
        pagination: action.pagination,
        message: action.message,
      };
    case SEARCH_BOOKINGS_FAILED:
      return { ...state, isLoading: false, message: action.message };
    case CANCEL_BOOKINGS:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: "",
      };
    case CANCEL_BOOKINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allBookings: state.allBookings.filter(
          (val) => val.id == action.payload
        ),
        message: action.message,
      };
    case CANCEL_BOOKINGS_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message,
      };
    case SELECT_BOOKING:
      return {
        ...state,
        bookingDetails: state.allBookings.find((v) => v.id === action.payload),
      };
    case FETCH_BOOKING_DETAILS:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: ""
      }
    case FETCH_BOOKING_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        bookingDetails: action.bookingDetail,
        message: action.message
      }
    case FETCH_BOOKING_DETAILS_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message
      }
    default:
      return state;
  }
};
