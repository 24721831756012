import { HIDE_MODAL, SHOW_MODAL } from "./../actions/modal.action";

const INITIAL_STATE = {
  addContractModal: false,
  editContractModal: false,
  addPriceRuleModal: false,
  editPriceRuleModal: false,
  addDurationPriceModal: false,
  editDurationPriceModal: false,
  addExtrasModal: false,
  editExtrasModal: false,
  bookingCanelConfirmModal: false,
  customerDetailModal: false,
};

export const ModalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return { ...state, [action.payload]: true };
    case HIDE_MODAL:
      return { ...state, [action.payload]: false };
    default:
      return state;
  }
};
