export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const SET_CURRENCIES = "SET_CURRENCIES";
export const SET_CUURENCIES_SUCCESS = "SET_CUURENCIES_SUCCESS";
export const SET_CUURENCIES_FAILED = "SET_CUURENCIES_FAILED";

export const LOGOUT = "LOGOUT";

export const fetchCurrencies = (payload) => ({
  type: SET_CURRENCIES,
  payload,
});

export const login = (payload, setSubmitting, push) => ({
  type: LOGIN,
  payload,
  setSubmitting,
  push,
});

export const logout = () => ({
  type: LOGOUT,
});
