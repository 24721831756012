import axios from "axios";
import { Observable } from "rxjs";
import { ofType } from "redux-observable";
import { switchMap } from "rxjs/operators";

import {
  HANDLE_OTP,
  HANDLE_OTP_FAILED,
  HANDLE_OTP_SUCCESS,
  
} from "../actions/OTP.action";

import {
    BASE,
    HANDLE_OTP_API,
} from "./../../config/api";
import { axiosClient } from "./auth.epic";

export class OTPEpic {
 
    static handleOTP = (action$) =>
        action$.pipe(
            ofType(HANDLE_OTP),
            switchMap(({payload}) => {
            return new Observable(async (observer) => {
              observer.next({
                type: HANDLE_OTP_SUCCESS,
                payload: payload,
              });
        });
      })
    );

  
}
