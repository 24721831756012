import axios from "axios";
import { Observable } from "rxjs";
import { ofType } from "redux-observable";
import { switchMap } from "rxjs/operators";

import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  SET_CURRENCIES,
  SET_CUURENCIES_SUCCESS,
  SET_CUURENCIES_FAILED,
} from "../actions/auth.action";
import { BASE, LOGIN_API, CURRENCIES_LIST_API } from "./../../config/api";

export const axiosClient = axios.create({
  baseURL: BASE,
});

export class AuthEpic {
  static login = (action$) =>
    action$.pipe(
      ofType(LOGIN),
      switchMap(({ payload, setSubmitting, push }) => {
        return new Observable(async (observer) => {
          try {
            const result = await axios.post(`${BASE}${LOGIN_API}`, payload);
            const { data } = result;
            if (data.data.token) {
              axiosClient.interceptors.request.use(function (config) {
                config.headers.Authorization = `Bearer ${data.data.token}`;
                return config;
              });
              observer.next({
                type: LOGIN_SUCCESS,
                user: data.data,
                token: data.data.token,
                message: "User Successfully logged in",
              });
              push("/dashboard");
              setSubmitting(false);
              window.location.reload();
            } else {
              observer.next({
                type: LOGIN_FAILED,
                user: {},
                message: "Invalid Username or Password",
              });
              setSubmitting(false);
            }
          } catch (err) {
            observer.next({
              type: LOGIN_FAILED,
              user: {},
              message: "Error in Login",
            });
            setSubmitting(false);
          }
        });
      })
    );

  static setCurrencies = (action$) =>
    action$.pipe(
      ofType(SET_CURRENCIES),
      switchMap(({ payload }) => {
        return new Observable(async (observer) => {
          try {
            const result = await axios.post(
              `${BASE}${CURRENCIES_LIST_API}`,
              payload
            );
            const { data } = result;
            if (data.success) {
              observer.next({
                type: SET_CUURENCIES_SUCCESS,
                currenciesList: data.data,
                message: "Fetched Currencies successfully",
              });
            } else {
              observer.next({
                type: SET_CUURENCIES_FAILED,
                message: "Failed to fetch currencies",
              });
            }
          } catch (err) {
            observer.next({
              type: SET_CUURENCIES_FAILED,
              message: "Error in Login",
            });
          }
        });
      })
    );
}
