export const GET_META_INFO = "GET_META_INFO";
export const GET_META_INFO_SUCCESS = "GET_META_INFO_SUCCESS";
export const GET_META_INFO_FAILED = "GET_META_INFO_FAILED";

export const GET_COUPON_LIST = "GET_COUPON_LIST";
export const GET_COUPON_LIST_SUCCESS = "GET_COUPON_LIST_SUCCESS";
export const GET_COUPON_LIST_FAILED = "GET_COUPON_LIST_FAILED";

export const GET_ALL_CONTRACTS = "GET_ALL_CONTRACTS";
export const GET_ALL_CONTRACTS_SUCCESS = "GET_ALL_CONTRACTS_SUCCESS";
export const GET_ALL_CONTRACTS_FAILED = "GET_ALL_CONTRACTS_FAILED";

export const CREATE_COUPON = "CREATE_COUPON";
export const CREATE_COUPON_SUCCESS = "CREATE_COUPON_SUCCESS";
export const CREATE_COUPON_FAILED = "CREATE_COUPON_FAILED";

export const UPDATE_COUPON = "UPDATE_COUPON";
export const UPDATE_COUPON_SUCCESS = "UPDATE_COUPON_SUCCESS";
export const UPDATE_COUPON_FAILED = "UPDATE_COUPON_FAILED";

export const DELETE_COUPON = "DELETE_COUPON";
export const DELETE_COUPON_SUCCESS = "DELETE_COUPON_SUCCESS";
export const DELETE_COUPON_FAILED = "DELETE_COUPON_FAILED";

export const GET_SUPPLIER_REGIONS = "GET_SUPPLIER_REGIONS";
export const GET_SUPPLIER_REGIONS_SUCCESS = "GET_SUPPLIER_REGIONS_SUCCESS";
export const GET_SUPPLIER_REGIONS_FAILED = "GET_SUPPLIER_REGIONS_FAILED";

export const GET_SUPPLIER_LOCATIONS = "GET_SUPPLIER_LOCATIONS";
export const GET_SUPPLIER_LOCATIONS_SUCCESS = "GET_SUPPLIER_LOCATIONS_SUCCESS";
export const GET_SUPPLIER_LOCATIONS_FAILED = "GET_SUPPLIER_LOCATIONS_FAILED";

export const TOGGLE_CUSTOM_LIST_ITEM = "TOGGLE_CUSTOM_LIST_ITEM";
export const ENABLE_ALL_CUSTOM_LIST_ITEMS = "ENABLE_ALL_CUSTOM_LIST_ITEMS";
export const DISABLE_ALL_CUSTOM_LIST_ITEMS = "DISABLE_ALL_CUSTOM_LIST_ITEMS";

export const GET_COUPON_DETAIL = "GET_COUPON_DETAIL";
export const GET_COUPON_DETAIL_SUCCESS = "GET_COUPON_DETAIL_SUCCESS";
export const GET_COUPON_DETAIL_FAILED = "GET_COUPON_DETAIL_FAILED";

export const SET_CONTRACT_LOCATIONS = "SET_CONTRACT_LOCATIONS";
export const SET_CONTRACT_REGIONS = "SET_CONTRACT_REGIONS";
export const IS_PICK_UP_UPDATING = "IS_PICK_UP_UPDATING";
export const IS_REGION_UPDATING = "IS_REGION_UPDATING";

export const GET_REGION_COUNTRIES = "GET_REGION_COUNTRIES";
export const GET_REGION_COUNTRIES_SUCCESS = "GET_REGION_COUNTRIES_SUCCESS";
export const GET_REGION_COUNTRIES_FAILED = "GET_REGION_COUNTRIES_FAILED";

export const SET_SELECTED_CONTRACT = "SET_SELECTED_CONTRACT";

export const SELECT_CONTRACT = "SELECT_CONTRACT";
export const CONTRACT_SUPPLIER_UPDATING = "CONTRACT_SUPPLIER_UPDATING";

export const getCouponList = () => ({
  type: GET_COUPON_LIST,
});

export const createCoupon = (payload, setSubmiting, hideModal) => ({
  type: CREATE_COUPON,
  payload,
  setSubmiting,
  hideModal,
});

export const updateCoupon = (payload, setSubmiting, hideModal) => ({
  type: UPDATE_COUPON,
  payload,
  setSubmiting,
  hideModal,
});

export const deleteCoupon = (payload, hideModal) => ({
  type: DELETE_COUPON,
  payload,
  hideModal,
});

export const getCouponDetail = (id, showModal) => ({
  type: GET_COUPON_DETAIL,
  id,
  showModal,
});

export const getContractMetaInfo = () => ({
  type: GET_META_INFO,
});

export const getSupplierRegions = (supplier_id) => ({
  type: GET_SUPPLIER_REGIONS,
  supplier_id,
});

export const getAllContracts = () => ({
  type: GET_ALL_CONTRACTS,
});

export const selectContract = () => ({
  type: SELECT_CONTRACT,
});

export const getSupplierLocations = (payload) => ({
  type: GET_SUPPLIER_LOCATIONS,
  payload,
});

export const toggleCustomListItem = (id, name) => ({
  type: TOGGLE_CUSTOM_LIST_ITEM,
  id,
  name,
});

export const enableAllCustomListItem = (name) => ({
  type: ENABLE_ALL_CUSTOM_LIST_ITEMS,
  name,
});

export const disableAllCustomListItem = (name) => ({
  type: DISABLE_ALL_CUSTOM_LIST_ITEMS,
  name,
});

export const setContractRegions = (payload) => ({
  type: SET_CONTRACT_REGIONS,
  payload,
});

export const setContractLocations = (payload) => ({
  type: SET_CONTRACT_LOCATIONS,
  payload,
});

export const setPickupUpdating = (payload) => ({
  type: IS_PICK_UP_UPDATING,
  payload,
});

export const setRegionUpdating = (payload) => ({
  type: IS_REGION_UPDATING,
  payload,
});

export const setSupplierContractUpdating = (payload) => ({
  type: CONTRACT_SUPPLIER_UPDATING,
  payload,
});

export const getRegionsCountries = (payload) => ({
  type: GET_REGION_COUNTRIES,
  payload,
});

export const setSelectedContract = (payload) => ({
  type: SET_SELECTED_CONTRACT,
  payload,
});
