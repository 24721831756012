export const GET_ALL_CUSTOMERS = "GET_ALL_CUSTOMERS";
export const SET_CUSTOMER_DETAILS = "SET_CUSTOMER_DETAILS";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_FAILED = "UPDATE_CUSTOMER_FAILED";

export const GET_ALL_CUSTOMERS_SUCCESS = "GET_ALL_CUSTOMERS_SUCCESS";
export const GET_ALL_CUSTOMERS_FAILED = "GET_ALL_CUSTOMERS_FAILED";

export const SET_CUSTOMER_STATUS = "SET_CUSTOMER_STATUS";
export const SET_CUSTOMER_STATUS_SUCCESS = "SET_CUSTOMER_STATUS_SUCCESS";
export const SET_CUSTOMER_STATUS_FAILED = "SET_CUSTOMER_STATUS_FAILED";

export const updateCustomer = (payload, setSubmiting, hideModal) => ({
  type: UPDATE_CUSTOMER,
  payload,
  setSubmiting,
  hideModal,
});

export const setCustomerDetails = (payload) => ({
  type: SET_CUSTOMER_DETAILS,
  payload,
});

export const getAllCustomers = () => ({
  type: GET_ALL_CUSTOMERS,
});

export const setCustomerStatus = (id, payload) => ({
  type: SET_CUSTOMER_STATUS,
  payload,
  id,
});
