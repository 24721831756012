import { createStore, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import { createEpicMiddleware } from "redux-observable";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { rootInitialState, rootReducer } from "./reducers/index";
import { epics } from "./epics/index";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["App","ModalState"]
};

const logger = createLogger({
  collapsed: true,
  predicate: (getState: Function, action: Object) => {
    if (action.type && action.type.startsWith("@@redux-form")) {
      return false;
    } else {
      return true;
    }
  }
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const epicMiddlewares = createEpicMiddleware();

const store = createStore(
  persistedReducer,
  rootInitialState,
  composeWithDevTools(applyMiddleware(epicMiddlewares, logger))
);

const persistor = persistStore(store);

epicMiddlewares.run(epics);

export { store, persistor };
