import _ from "lodash";
import {
  GET_COUPON_LIST,
  GET_COUPON_LIST_FAILED,
  GET_COUPON_LIST_SUCCESS,
  GET_META_INFO,
  GET_META_INFO_FAILED,
  GET_META_INFO_SUCCESS,
  GET_SUPPLIER_REGIONS,
  GET_SUPPLIER_REGIONS_FAILED,
  GET_SUPPLIER_REGIONS_SUCCESS,
  GET_REGION_COUNTRIES,
  GET_REGION_COUNTRIES_FAILED,
  GET_REGION_COUNTRIES_SUCCESS,
  GET_ALL_CONTRACTS,
  GET_ALL_CONTRACTS_FAILED,
  GET_ALL_CONTRACTS_SUCCESS,
  GET_SUPPLIER_LOCATIONS,
  GET_SUPPLIER_LOCATIONS_FAILED,
  GET_SUPPLIER_LOCATIONS_SUCCESS,
  CREATE_COUPON,
  CREATE_COUPON_SUCCESS,
  CREATE_COUPON_FAILED,
  TOGGLE_CUSTOM_LIST_ITEM,
  ENABLE_ALL_CUSTOM_LIST_ITEMS,
  DISABLE_ALL_CUSTOM_LIST_ITEMS,
  GET_COUPON_DETAIL,
  GET_COUPON_DETAIL_FAILED,
  GET_COUPON_DETAIL_SUCCESS,
  SET_CONTRACT_REGIONS,
  SET_CONTRACT_LOCATIONS,
  IS_PICK_UP_UPDATING,
  UPDATE_COUPON,
  UPDATE_COUPON_FAILED,
  UPDATE_COUPON_SUCCESS,
  DELETE_COUPON,
  DELETE_COUPON_FAILED,
  DELETE_COUPON_SUCCESS,
  CONTRACT_SUPPLIER_UPDATING,
  IS_REGION_UPDATING,
  SET_SELECTED_CONTRACT,
} from "./../actions/coupon.action";

const INITIAL_STATE = {
  contracts: [],
  coupons: [],
  selectedContractDetail: {},
  selectedCouponDetail: {},
  metaInfo: [],
  supplierRegions: {},
  isPickupUpdating: false,
  isRegionUpdating: false,
  isContractSupplierUpdating: false,
  locations: [],
  isLoading: false,
  isLocationsLoading: false,
  isError: false,
  message: "",
};

export const CouponReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_COUPON_LIST:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: "",
      };
    case GET_COUPON_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError:false,
        coupons : action.payload,
        message: action.message,
      };
    case GET_COUPON_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        message: action.message,
        isError: true,
      };
    case CREATE_COUPON:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: "",
      };
    case CREATE_COUPON_SUCCESS:
      return {
        ...state,
        isLoading: false,
        coupons: state.coupons.concat(action.payload),
        message: action.message,
      };
    case CREATE_COUPON_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message,
      };
    case UPDATE_COUPON:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: "",
      };
    case UPDATE_COUPON_SUCCESS:
      return {
        ...state,
        isLoading: false,
        coupons: state.coupons.map((item) => {
          return item.id === action.payload.id ? action.payload : item;
        }),
        message: action.message,
      };
    case UPDATE_COUPON_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message,
      };
    case DELETE_COUPON:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: "",
      };
    case DELETE_COUPON_SUCCESS:
      return {
        ...state,
        isLoading: false,
        coupons: state.coupons.filter((i) => i.id !== action.payload.id),
        message: action.message,
      };
    case DELETE_COUPON_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message,
      };
    case GET_ALL_CONTRACTS:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: "",
      };
    case GET_ALL_CONTRACTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        contracts: action.payload,
        message: action.message,
      };
    case GET_ALL_CONTRACTS_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message,
      };
    case GET_META_INFO:
      return { ...state, isLoading: true, isError: false, message: "" };
    case GET_META_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        metaInfo: action.payload,
        message: action.message,
      };
    case GET_META_INFO_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message,
      };
    case GET_SUPPLIER_REGIONS:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: "",
      };
    case GET_SUPPLIER_REGIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        supplierRegions: action.payload,
        message: action.message,
      };
    case GET_SUPPLIER_REGIONS_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message,
      };
    case GET_REGION_COUNTRIES:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: "",
      };
    case GET_REGION_COUNTRIES_SUCCESS:
      let pick_up_countries;
      if (!_.isEmpty(state.selectedContractDetail)) {
        pick_up_countries = {
          pick_up: action.payload.map((v) => {
            if (
              state.selectedContractDetail.pickup_countries.find(
                (x) => x.code === v.code
              )
            ) {
              return {
                ...v,
                is_selected: true,
              };
            }
            return v;
          }),
        };
      } else {
        pick_up_countries = { pick_up: action.payload };
      }
      return {
        ...state,
        isLoading: false,
        supplierRegions: { ...state.supplierRegions, ...pick_up_countries },
        message: action.message,
      };
    case GET_REGION_COUNTRIES_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message,
      };
    case GET_SUPPLIER_LOCATIONS:
      return {
        ...state,
        isLocationsLoading: true,
        isError: false,
        message: "",
      };
    case GET_SUPPLIER_LOCATIONS_SUCCESS:
      let locations;
      if (!_.isEmpty(state.selectedContractDetail)) {
        locations = action.payload.map((v) => {
          if (
            state.selectedContractDetail.locations.find(
              (x) => x.code === v.code
            )
          ) {
            return {
              ...v,
              is_selected: true,
            };
          }
          return v;
        });
      } else {
        locations = action.payload;
      }

      return {
        ...state,
        isLocationsLoading: false,
        locations: locations,
        message: action.message,
      };
    case GET_SUPPLIER_LOCATIONS_FAILED:
      return {
        ...state,
        isLocationsLoading: false,
        isError: true,
        message: action.message,
      };
    case TOGGLE_CUSTOM_LIST_ITEM:
      if (action.name === "locations") {
        return {
          ...state,
          locations: state.locations.map((v) => {
            if (v.id === action.id) {
              return {
                ...v,
                is_selected: !v.is_selected,
              };
            }
            return v;
          }),
        };
      } else {
        return {
          ...state,
          supplierRegions: {
            ...state.supplierRegions,
            [action.name]: state.supplierRegions[action.name].map((v) => {
              if (v.id === action.id) {
                return {
                  ...v,
                  is_selected: !v.is_selected,
                };
              }
              return v;
            }),
          },
        };
      }

    case ENABLE_ALL_CUSTOM_LIST_ITEMS:
      if (action.name === "locations") {
        return {
          ...state,
          locations: state.locations.map((v) => {
            return {
              ...v,
              is_selected: true,
            };
          }),
        };
      } else {
        return {
          ...state,
          supplierRegions: {
            ...state.supplierRegions,
            [action.name]: state.supplierRegions[action.name].map((v) => {
              return {
                ...v,
                is_selected: true,
              };
            }),
          },
        };
      }
    case DISABLE_ALL_CUSTOM_LIST_ITEMS:
      if (action.name === "locations") {
        return {
          ...state,
          locations: state.locations.map((v) => {
            return {
              ...v,
              is_selected: false,
            };
          }),
        };
      } else {
        return {
          ...state,
          supplierRegions: {
            ...state.supplierRegions,
            [action.name]: state.supplierRegions[action.name].map((v) => {
              return {
                ...v,
                is_selected: false,
              };
            }),
          },
        };
      }
    case GET_COUPON_DETAIL:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: "",
        selectedCouponDetail: {},
      };
    case GET_COUPON_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedCouponDetail: action.payload,
        message: action.message,
      };
    case GET_COUPON_DETAIL_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message,
      };
    case SET_CONTRACT_REGIONS:
      return {
        ...state,
        supplierRegions: action.payload,
      };
    case SET_CONTRACT_LOCATIONS:
      return {
        ...state,
        locations: action.payload,
      };
    case IS_PICK_UP_UPDATING:
      return {
        ...state,
        isPickupUpdating: action.payload,
      };
    case IS_REGION_UPDATING:
      return {
        ...state,
        isRegionUpdating: action.payload,
      };
    case CONTRACT_SUPPLIER_UPDATING:
      return {
        ...state,
        isContractSupplierUpdating: action.payload,
      };
    case SET_SELECTED_CONTRACT:
      return {
        ...state,
        selectedContractDetail: action.payload,
      };
    default:
      return state;
  }
};
