import {
  GET_ALL_CUSTOMERS,
  GET_ALL_CUSTOMERS_FAILED,
  GET_ALL_CUSTOMERS_SUCCESS,
  SET_CUSTOMER_STATUS,
  SET_CUSTOMER_STATUS_FAILED,
  SET_CUSTOMER_STATUS_SUCCESS,
  SET_CUSTOMER_DETAILS,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILED,
} from "./../actions/customer.action";

const INITIAL_STATE = {
  allCustomers: [],
  isLoading: false,
  isError: false,
  message: "",
};

export const CustomerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_CUSTOMER:
    case GET_ALL_CUSTOMERS:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: "",
      };
    case GET_ALL_CUSTOMERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allCustomers: action.payload,
        message: action.message,
      };
    case UPDATE_CUSTOMER_FAILED:
    case GET_ALL_CUSTOMERS_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.message,
      };
    case SET_CUSTOMER_STATUS:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: "",
      };
    case SET_CUSTOMER_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allCustomers: state.allCustomers.map((item) => {
          if (item.id == action.payload.id) {
            return {
              ...item,
              is_active: action.payload.is_active,
              status: action.payload.status,
            };
          } else {
            return item;
          }
        }),
        message: action.message,
      };
    case SET_CUSTOMER_STATUS_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: false,
        message: action.message,
      };
    case SET_CUSTOMER_DETAILS:
      return {
        ...state,
        customerDetails: state.allCustomers.find(
          (v) => v.id === action.payload
        ),
      };
    case UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allCustomers: state.allCustomers.map((item) => {
          return item.id === action.payload.id ? action.payload : item;
        }),
        message: action.message,
      };
    default:
      return state;
  }
};
