export const GET_INTEGRATIONS = "GET_INTEGRATIONS";
export const GET_INTEGRATIONS_SUCCESS = "GET_INTEGRATIONS_SUCCESS";
export const GET_INTEGRATIONS_FAILED = "GET_INTEGRATIONS_FAILED";

export const GET_SUPPLIERS = "GET_SUPPLIERS";
export const GET_SUPPLIERS_SUCCESS = "GET_SUPPLIERS_SUCCESS";
export const GET_SUPPLIERS_FAILED = "GET_SUPPLIERS_FAILED";

export const getIntegrations = () => ({
  type: GET_INTEGRATIONS
});

export const getSuppliers = () => ({
  type: GET_SUPPLIERS
});
