import { combineEpics } from "redux-observable";

import { AppEpic } from "./app.epic";
import { AvailableCarsEpic } from "./availableCars.epic";
import { CarReservationEpic } from "./carReservation.epic";
import { AuthEpic } from "./auth.epic";
import { BookingsEpic } from "./bookings.epic";
import { ContractEpic } from "./contracts.epic";
import { PriceRulesEpic } from "./priceRules.epic";
import { SupplyEpic } from "./supply.epic";
import { ExtrasEpic } from "./extras.epic";
import { CustomersEpic } from "./customers.epic";
import { SwitchPaymentEpic } from "./switchPayment.epic";
import { OTPEpic } from "./OTP.epic";
import { CouponEpic } from "./coupon.epic";

export const epics = combineEpics(
  AppEpic.resetState,
  AuthEpic.login,
  AuthEpic.setCurrencies,
  BookingsEpic.searchBookings,
  BookingsEpic.cancelBooking,
  BookingsEpic.setReminder,
  BookingsEpic.sendCheckinEmail,
  BookingsEpic.sendCheckoutEmail,
  BookingsEpic.fetchBookingDetails,
  AvailableCarsEpic.searchAvailableCars,
  AvailableCarsEpic.getSearchVehicleFilter,
  CarReservationEpic.makeCarReservation,
  ContractEpic.getMetaInfo,
  ContractEpic.getSupplierRegions,
  ContractEpic.getAllContracts,
  ContractEpic.getSupplierLocations,
  ContractEpic.createContract,
  ContractEpic.getContractDetail,
  ContractEpic.updateContract,
  ContractEpic.getRegionCountries,
  ContractEpic.deleteContract,
  PriceRulesEpic.createPriceRules,
  PriceRulesEpic.updatePriceRule,
  PriceRulesEpic.getAllPriceRules,
  PriceRulesEpic.getPriceRuleDetail,
  PriceRulesEpic.getPriceRuleOptions,
  PriceRulesEpic.getPriceRuleContractLocations,
  PriceRulesEpic.deletePriceRule,
  PriceRulesEpic.getPriceRuleRegionCountries,
  PriceRulesEpic.getPriceRuleCountriesLocations,
  ExtrasEpic.createExtras,
  ExtrasEpic.updateExtras,
  ExtrasEpic.deleteExtras,
  ExtrasEpic.getAllExtras,
  ExtrasEpic.getExtrasDetail,
  ExtrasEpic.getExtrasOptions,
  ExtrasEpic.getExtrasContractLocations,
  ExtrasEpic.getExtrasCountriesLocations,
  ExtrasEpic.getExtrasRegionCountries,
  SupplyEpic.getIntegrations,
  SupplyEpic.getSuppliers,
  CustomersEpic.getCustomers,
  CustomersEpic.setCustomerStatus,
  CustomersEpic.updateCustomer,
  SwitchPaymentEpic.getPaymentMode,
  SwitchPaymentEpic.setPaymentMode,
  OTPEpic.handleOTP,
  CouponEpic.getCouponList,
  CouponEpic.createCoupon,
  CouponEpic.deleteCoupon,
  CouponEpic.getCouponDetail,
  CouponEpic.updateCoupon
);
