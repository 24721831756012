import { Observable } from "rxjs";
import { ofType } from "redux-observable";
import { switchMap } from "rxjs/operators";

import {
  GET_ALL_CUSTOMERS,
  GET_ALL_CUSTOMERS_FAILED,
  GET_ALL_CUSTOMERS_SUCCESS,
  SET_CUSTOMER_STATUS,
  SET_CUSTOMER_STATUS_FAILED,
  SET_CUSTOMER_STATUS_SUCCESS,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILED,
} from "../actions/customer.action";
import {
  BASE,
  GET_CUSTOMERS_API,
  SET_CUSTOMER_STATUS_API,
  UPDATE_CUSTOMER_API,
} from "../../config/api";
import { axiosClient } from "./auth.epic";

export class CustomersEpic {
  static getCustomers = (action$) =>
    action$.pipe(
      ofType(GET_ALL_CUSTOMERS),
      switchMap(() => {
        return new Observable(async (observer) => {
          try {
            const result = await axiosClient.get(`${GET_CUSTOMERS_API}`);
            const { data } = result;
            if (data.success) {
              observer.next({
                type: GET_ALL_CUSTOMERS_SUCCESS,
                payload: data.data,
                message: "Get Customers Success",
              });
            } else {
              observer.next({
                type: GET_ALL_CUSTOMERS_FAILED,
                message: "Get Customers Failed",
              });
            }
          } catch (err) {
            observer.next({
              type: GET_ALL_CUSTOMERS_FAILED,
              message: "Error in getting Customers",
            });
          }
        });
      })
    );
  static setCustomerStatus = (action$) =>
    action$.pipe(
      ofType(SET_CUSTOMER_STATUS),
      switchMap(({ payload, id }) => {
        return new Observable(async (observer) => {
          try {
            const result = await axiosClient.post(
              `${SET_CUSTOMER_STATUS_API}${id}/status`,
              payload
            );
            const { data } = result;
            if (data.success) {
              observer.next({
                type: SET_CUSTOMER_STATUS_SUCCESS,
                payload: data.data,
                message: data.msg,
              });
            } else {
              observer.next({
                type: SET_CUSTOMER_STATUS_FAILED,
                message: data.msg,
              });
            }
          } catch (err) {
            observer.next({
              type: SET_CUSTOMER_STATUS_FAILED,
              message: "Error in setting customer status",
            });
          }
        });
      })
    );

  static updateCustomer = (action$) =>
    action$.pipe(
      ofType(UPDATE_CUSTOMER),
      switchMap(({ payload, setSubmiting, hideModal }) => {
        return new Observable(async (observer) => {
          try {
            const { data } = await axiosClient.post(
              `${UPDATE_CUSTOMER_API}`,
              payload
            );

            if (data.success) {
              setSubmiting(false);
              hideModal();
              observer.next({
                type: UPDATE_CUSTOMER_SUCCESS,
                payload: data.data,
                message: data.message,
              });
            } else {
              setSubmiting(false);
              observer.next({
                type: UPDATE_CUSTOMER_FAILED,
                message: data.message,
              });
            }
          } catch (err) {
            setSubmiting(false);
            observer.next({
              type: UPDATE_CUSTOMER_FAILED,
              message: err.response.data.message,
            });
          }
        });
      })
    );
}
