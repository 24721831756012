export const CREATE_EXTRAS = "CREATE_EXTRAS";
export const CREATE_EXTRAS_SUCCESS = "CREATE_EXTRAS_SUCCESS";
export const CREATE_EXTRAS_FAILED = "CREATE_EXTRAS_FAILED";

export const UPDATE_EXTRAS = "UPDATE_EXTRAS";
export const UPDATE_EXTRAS_SUCCESS = "UPDATE_EXTRAS_SUCCESS";
export const UPDATE_EXTRAS_FAILED = "UPDATE_EXTRAS_FAILED";

export const GET_ALL_EXTRAS = "GET_ALL_EXTRAS";
export const GET_ALL_EXTRAS_SUCCESS = "GET_ALL_EXTRAS_SUCCESS";
export const GET_ALL_EXTRAS_FAILED = "GET_ALL_EXTRAS_FAILED";

export const GET_EXTRAS_DETAIL = "GET_EXTRAS_DETAIL";
export const GET_EXTRAS_DETAIL_SUCCESS = "GET_EXTRAS_DETAIL_SUCCESS";
export const GET_EXTRAS_DETAIL_FAILED = "GET_EXTRAS_DETAIL_FAILED";

export const TOGGLE_EXTRAS_ITEM = "TOGGLE_EXTRAS_ITEM";
export const ENABLE_ALL_EXTRAS_ITEMS = "ENABLE_ALL_EXTRAS_ITEMS";
export const DISABLE_ALL_EXTRAS_ITEMS = "DISABLE_ALL_EXTRAS_ITEMS";

export const DELETE_EXTRAS = "DELETE_EXTRAS";
export const DELETE_EXTRAS_SUCCESS = "DELETE_EXTRAS_SUCCESS";
export const DELETE_EXTRAS_FAILED = "DELETE_EXTRAS_FAILED";

export const GET_EXTRAS_OPTIONS = "GET_EXTRAS_OPTIONS";
export const GET_EXTRAS_OPTIONS_SUCCESS = "GET_EXTRAS_OPTIONS_SUCCESS";
export const GET_EXTRAS_OPTIONS_FAILED = "GET_EXTRAS_OPTIONS_FAILED";

export const GET_EXTRAS_CONTRACT_LOCATIONS = "GET_EXTRAS_CONTRACT_LOCATIONS";
export const GET_EXTRAS_CONTRACT_LOCATIONS_SUCCESS =
  "GET_EXTRAS_CONTRACT_LOCATIONS_SUCCESS";
export const GET_EXTRAS_CONTRACT_LOCATIONS_FAILED =
  "GET_EXTRAS_CONTRACT_LOCATIONS_FAILED";
export const CONTRACT_IS_UPDATING = "CONTRACT_IS_UPDATING";
export const SET_EXTRAS_CUSTOM_BOX_UPDATING = "SET_EXTRAS_CUSTOM_BOX_UPDATING";

export const GET_EXTRAS_REGION_COUNTRIES = "GET_EXTRAS_REGION_COUNTRIES";
export const GET_EXTRAS_REGION_COUNTRIES_SUCCESS =
  "GET_EXTRAS_REGION_COUNTRIES_SUCCESS";
export const GET_EXTRAS_REGION_COUNTRIES_FAILED =
  "GET_EXTRAS_REGION_COUNTRIES_FAILED";

export const GET_EXTRAS_COUNTRIES_LOCATIONS = "GET_EXTRAS_COUNTRIES_LOCATIONS";
export const GET_EXTRAS_COUNTRIES_LOCATIONS_SUCCESS =
  "GET_EXTRAS_COUNTRIES_LOCATION_SUCCESSS";
export const GET_EXTRAS_COUNTRIES_LOCATIONS_FAILED =
  "GET_EXTRAS_COUNTRIES_LOCATIONS_FAILED";

export const createExtras = (payload, setSubmiting, hideModal) => ({
  type: CREATE_EXTRAS,
  payload,
  setSubmiting,
  hideModal,
});

export const updateExtras = (payload, setSubmiting, hideModal) => ({
  type: UPDATE_EXTRAS,
  payload,
  setSubmiting,
  hideModal,
});

export const deleteExtras = (id) => ({
  type: DELETE_EXTRAS,
  id,
});

export const getAllExtras = (payload) => ({
  type: GET_ALL_EXTRAS,
  payload,
});

export const getExtrasDetail = (id, showModal) => ({
  type: GET_EXTRAS_DETAIL,
  id,
  showModal,
});

export const toggleExtrasItem = (id, name) => ({
  type: TOGGLE_EXTRAS_ITEM,
  id,
  name,
});

export const enableAllExtrasItem = (name) => ({
  type: ENABLE_ALL_EXTRAS_ITEMS,
  name,
});

export const disableAllExtrasItem = (name) => ({
  type: DISABLE_ALL_EXTRAS_ITEMS,
  name,
});

export const getExtrasOptions = () => ({
  type: GET_EXTRAS_OPTIONS,
});

export const getExtrasContractLocation = (payload) => ({
  type: GET_EXTRAS_CONTRACT_LOCATIONS,
  payload,
});

export const setContractUpdating = (payload) => ({
  type: CONTRACT_IS_UPDATING,
  payload,
});

export const getExtrasRegionCountries = (payload) => ({
  type: GET_EXTRAS_REGION_COUNTRIES,
  payload,
});

export const getExtrasCountriesLocations = (payload) => ({
  type: GET_EXTRAS_COUNTRIES_LOCATIONS,
  payload,
});

export const setExtrasCustomBoxUpdating = (name, payload) => ({
  type: SET_EXTRAS_CUSTOM_BOX_UPDATING,
  name,
  payload,
});
