import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Spinner } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import Routes from "./routes/PublicRoutes";
import "./App.scss";
import styles from "./styles.module.scss";

import { getSearchVehicleFilter } from "./store/actions/availableCars.action";
import { axiosClient } from "./store/epics/auth.epic";
import {
  getPaymentMode,
} from "./store/actions/switchPayment.action";

const App = () => {
  let { response, message, type, isLoading } = useSelector(
    (state) => state.App
  );
  response && type === "Success" && toast.info(message);
  type === "Error" && toast.error(message);
  const { token } = useSelector((state) => state.Auth);

  const Dispatch = useDispatch();
  useEffect(() => {
    axiosClient.interceptors.request.use(function (config) {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });
    Dispatch(getPaymentMode());
    Dispatch(getSearchVehicleFilter(token));
  }, []);

  const stripePromise = loadStripe(
    "pk_test_ie5Z9jgCVR0kQxqDdLsc5onz00z5z0IlrX"
  );

  return (
    <Elements stripe={stripePromise}>
      <div>
        <Routes />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          style={{ zIndex: 1999 }}
        />
        {isLoading && (
          <div className={styles.spinner_container}>
            <Spinner type="grow" color="primary" />
          </div>
        )}
      </div>
    </Elements>
  );
};
export default App;
