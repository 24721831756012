export const MAKE_CAR_RESERVATION = "MAKE_CAR_RESERVATION";
export const MAKE_CAR_RESERVATION_SUCCESS = "MAKE_CAR_RESERVATION_SUCCESS";
export const MAKE_CAR_RESERVATION_FAILED = "MAKE_CAR_RESERVATION_FAILED";

export const SELECT_CAR = "SELECT_CAR";
export const SET_DRIVER_DETAIL = "SET_DRIVER_DETAIL";
export const CLEAR_STEPPER_FORM = "CLEAR_STEPPER_FORM";

export const TOGGLE_EXTRAS = "TOGGLE_EXTRAS";
export const INCREMENT_EXTRAS_COUNT = "INCREMENT_EXTRAS_COUNT";
export const DECREMENT_EXTRAS_COUNT = "DECREMENT_EXTRAS_COUNT";

export const selectCar = (payload) => ({
  type: SELECT_CAR,
  payload,
});

export const setDriverDetails = (key, value) => ({
  type: SET_DRIVER_DETAIL,
  key,
  value,
});

export const toggleExtras = (payload) => ({
  type: TOGGLE_EXTRAS,
  payload,
});

export const incrementExtrasCount = (payload) => ({
  type: INCREMENT_EXTRAS_COUNT,
  payload,
});

export const decrementExtrasCount = (payload) => ({
  type: DECREMENT_EXTRAS_COUNT,
  payload,
});

export const makeCarReservation = (
  payload,
  dispatch,
  setWorkingStepId,
  setCurrentStepId,
  token
) => ({
  type: MAKE_CAR_RESERVATION,
  payload,
  dispatch,
  setWorkingStepId,
  setCurrentStepId,
  token,
});

export const clearStepperForm = () => ({
  type: CLEAR_STEPPER_FORM,
});
