import axios from "axios";
import { Observable } from "rxjs";
import { ofType } from "redux-observable";
import { switchMap } from "rxjs/operators";

import {
  GET_PAYMENT_MODE,
  GET_PAYMENT_MODE_FAILED,
  GET_PAYMENT_MODE_SUCCESS,
  SET_PAYMENT_MODE,
  SET_PAYMENT_MODE_FAILED,
  SET_PAYMENT_MODE_SUCCESS,

} from "../actions/switchPayment.action";

import {
    BASE,
    GET_PAYMENT_MODE_API,
    SET_PAYMENT_MODE_API,
} from "./../../config/api";
import { axiosClient } from "./auth.epic";

export class SwitchPaymentEpic {
  static getPaymentMode = (action$) =>
    action$.pipe(
      ofType(GET_PAYMENT_MODE),
      switchMap(() => {
        
        return new Observable(async (observer) => {
          try {
            const { data } = await axiosClient.get(
              `${BASE}${GET_PAYMENT_MODE_API}`,
            );
            
            if (data.success) {
              observer.next({
                type: GET_PAYMENT_MODE_SUCCESS,
                payload: data.message,
                message: "Payment Mode Fetched Successfully",
              });
            } else {
              observer.next({
                type: GET_PAYMENT_MODE_FAILED,
                message: "Something went wrong",
              });
            }
          } catch (err) {
            observer.next({
              type: GET_PAYMENT_MODE_FAILED,
              message: "Something went wrong",
            });
          }
        });
      })
    );

    static setPaymentMode = (action$) =>
    action$.pipe(
      ofType(SET_PAYMENT_MODE),
      switchMap(({payload}) => {
      
        return new Observable(async (observer) => {
          try {
            const { data } = await axiosClient.post(
              `${BASE}${SET_PAYMENT_MODE_API}`,
              payload
            );
            console.log(data);
            if (data.success) {
              observer.next({
                type: SET_PAYMENT_MODE_SUCCESS,
                payload: data.message,
                message: "Payment Mode Fetched Successfully",
              });
            } else {
              observer.next({
                type: SET_PAYMENT_MODE_FAILED,
                message: "Something went wrong",
              });
            }
          } catch (err) {
            observer.next({
              type: SET_PAYMENT_MODE_FAILED,
              message: "Something went wrong",
            });
          }
        });
      })
    );

  
}
