import { combineReducers } from "redux";

import { AppReducer } from "./app.reducer";
import { AuthReducer } from "./auth.reducer";
import { AvailableCarsReducer } from "./availableCars.reducer";
import { BookingsReducer } from "./bookings.reducer";
import { CarReservationReducer } from "./carReservation.reducer";
import { ModalReducer } from "./modal.reducer";
import { ContractReducer } from "./contracts.reducer";
import { PriceRulesReducer } from "./priceRules.reducer";
import { SupplyReducer } from "./supply.reducer";
import { ExtrasReducer } from "./extras.reducer";
import { CustomerReducer } from "./customers.reducer";
import { SwitchPaymentReducer } from "./switchPayment.reducer";
import { OTPReducer } from "./OTP.reducer";
import { CouponReducer } from "./coupon.reducer";

export const rootInitialState = {};

export let rootReducer = combineReducers({
  App: AppReducer,
  Auth: AuthReducer,
  ModalState: ModalReducer,
  Bookings: BookingsReducer,
  AvailableCars: AvailableCarsReducer,
  CarReservation: CarReservationReducer,
  Contracts: ContractReducer,
  PriceRules: PriceRulesReducer,
  Supply: SupplyReducer,
  Extras: ExtrasReducer,
  Customers: CustomerReducer,
  SwitchPayment :SwitchPaymentReducer,
  OTP : OTPReducer,
  Coupons : CouponReducer,
});
