import {
    HANDLE_OTP,
    HANDLE_OTP_FAILED,
    HANDLE_OTP_SUCCESS,
  } from "../actions/OTP.action";
  
  const INITIAL_STATE = {
    mail_result: "",
    otp : ""
  };
  
  export const OTPReducer = (state = INITIAL_STATE,action) => {
    switch (action.type) {
      case HANDLE_OTP:
        return {
          ...state,
          isLoading: true,
          isError: false,
          message: "",
          otp:""
        };
        case HANDLE_OTP_SUCCESS:
          return {
            ...state,
            isLoading: false,
            isError:false,
            otp:action.payload
          };
        
      
      default:
        return state;
    }
  };
  