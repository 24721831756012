import axios from "axios";
import { Observable } from "rxjs";
import { ofType } from "redux-observable";
import { switchMap } from "rxjs/operators";

import {
  SEARCH_AVAILABLE_CARS,
  SEARCH_AVAILABLE_CARS_FAILED,
  SEARCH_AVAILABLE_CARS_SUCCESS,
  GET_SEARCH_VEHICLE_FILTER,
  GET_SEARCH_VEHICLE_FILTER_SUCCESS,
  GET_SEARCH_VEHICLE_FILTER_FAILED,
} from "../actions/availableCars.action";

import {
  BASE,
  AVAILABLE_CARS_API,
  GET_SEARCH_VEHICLE_FILTERS_API,
} from "./../../config/api";
import { axiosClient } from "./auth.epic";

export class AvailableCarsEpic {
  static searchAvailableCars = (action$) =>
    action$.pipe(
      ofType(SEARCH_AVAILABLE_CARS),
      switchMap(({ payload }) => {
        return new Observable(async (observer) => {
          try {
            const { data } = await axiosClient.post(
              `${AVAILABLE_CARS_API}?page=${payload.page}&limit=${payload.limit}`,
              payload
            );
            if (data && data.data.length > 0) {
              observer.next({
                type: SEARCH_AVAILABLE_CARS_SUCCESS,
                payload: data.data,
                pagination: data.pagination,
                message: "Car Fetched Successfully",
              });
            } else {
              observer.next({
                type: SEARCH_AVAILABLE_CARS_FAILED,
                message: "No Cars Found",
              });
            }
          } catch (err) {
            observer.next({
              type: SEARCH_AVAILABLE_CARS_FAILED,
              message: "Error in Car Fetching",
            });
          }
        });
      })
    );

  static getSearchVehicleFilter = (action$) =>
    action$.pipe(
      ofType(GET_SEARCH_VEHICLE_FILTER),
      switchMap(({ token }) => {
        return new Observable(async (observer) => {
          try {
            const result = await axios.get(
              `${GET_SEARCH_VEHICLE_FILTERS_API}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );
            const { data } = result;
            if (data.success) {
              observer.next({
                type: GET_SEARCH_VEHICLE_FILTER_SUCCESS,
                payload: data.data,
                message: "Get all countries success",
              });
            } else {
              observer.next({
                type: GET_SEARCH_VEHICLE_FILTER_FAILED,
                message: "Get all countries failed",
              });
            }
          } catch (err) {
            const errorMessage = err.response.data.message;
            observer.next({
              type: GET_SEARCH_VEHICLE_FILTER_FAILED,
              message: errorMessage
                ? errorMessage
                : "Error in getting countries",
            });
          }
        });
      })
    );
}
