import { Observable } from "rxjs";
import { ofType } from "redux-observable";
import { switchMap } from "rxjs/operators";
import _ from "lodash";

import {
  CREATE_EXTRAS,
  CREATE_EXTRAS_FAILED,
  CREATE_EXTRAS_SUCCESS,
  UPDATE_EXTRAS,
  UPDATE_EXTRAS_SUCCESS,
  UPDATE_EXTRAS_FAILED,
  DELETE_EXTRAS,
  DELETE_EXTRAS_FAILED,
  DELETE_EXTRAS_SUCCESS,
  GET_ALL_EXTRAS,
  GET_ALL_EXTRAS_FAILED,
  GET_ALL_EXTRAS_SUCCESS,
  GET_EXTRAS_DETAIL,
  GET_EXTRAS_DETAIL_FAILED,
  GET_EXTRAS_DETAIL_SUCCESS,
  GET_EXTRAS_OPTIONS,
  GET_EXTRAS_OPTIONS_FAILED,
  GET_EXTRAS_OPTIONS_SUCCESS,
  GET_EXTRAS_CONTRACT_LOCATIONS,
  GET_EXTRAS_CONTRACT_LOCATIONS_FAILED,
  GET_EXTRAS_CONTRACT_LOCATIONS_SUCCESS,
  GET_EXTRAS_REGION_COUNTRIES,
  GET_EXTRAS_REGION_COUNTRIES_SUCCESS,
  GET_EXTRAS_REGION_COUNTRIES_FAILED,
  GET_EXTRAS_COUNTRIES_LOCATIONS,
  GET_EXTRAS_COUNTRIES_LOCATIONS_FAILED,
  GET_EXTRAS_COUNTRIES_LOCATIONS_SUCCESS,
} from "../actions/extras.action";
import {
  BASE,
  CREATE_EXTRAS_API,
  UPDATE_EXTRAS_API,
  GET_ALL_EXTRAS_API,
  GET_EXTRAS_DETAIL_API,
  GET_EXTRAS_OPTIONS_API,
  DELETE_EXTRAS_API,
  GET_EXTRAS_CONTRACT_LOCATIONS_API,
  GET_EXTRAS_REGION_COUNTRIES_API,
  GET_EXTRAS_COUNTRIES_LOCATION_API,
  GET_PRICE_RULE_OPTIONS_API,
} from "./../../config/api";
import { axiosClient } from "./auth.epic";

export class ExtrasEpic {
  static createExtras = (action$) =>
    action$.pipe(
      ofType(CREATE_EXTRAS),
      switchMap(({ payload, setSubmiting, hideModal }) => {
        return new Observable(async (observer) => {
          try {
            const result = await axiosClient.post(
              `${CREATE_EXTRAS_API}`,
              payload
            );
            const { data } = result;
            if (data.success) {
              observer.next({
                type: CREATE_EXTRAS_SUCCESS,
                payload: data.data,
                message: "Extra created successful",
              });
              setSubmiting(false);
              hideModal();
            } else {
              observer.next({
                type: CREATE_EXTRAS_FAILED,
                message: "Extra create failed",
              });
              setSubmiting(false);
            }
          } catch (err) {
            observer.next({
              type: CREATE_EXTRAS_FAILED,
              message: "Error in creating Extras",
            });
            setSubmiting(false);
          }
        });
      })
    );
  static updateExtras = (action$) =>
    action$.pipe(
      ofType(UPDATE_EXTRAS),
      switchMap(({ payload, setSubmiting, hideModal }) => {
        return new Observable(async (observer) => {
          try {
            const result = await axiosClient.post(
              `${UPDATE_EXTRAS_API}`,
              payload
            );
            const { data } = result;
            if (data.success) {
              observer.next({
                type: UPDATE_EXTRAS_SUCCESS,
                payload: data.data,
                message: "Extras Updated Successful",
              });
              setSubmiting(false);
              hideModal();
            } else {
              observer.next({
                type: UPDATE_EXTRAS_FAILED,
                message: "Extras update failed",
              });
              setSubmiting(false);
            }
          } catch (err) {
            observer.next({
              type: UPDATE_EXTRAS_FAILED,
              message: "Error in updating Extras",
            });
            setSubmiting(false);
          }
        });
      })
    );
  static deleteExtras = (action$) =>
    action$.pipe(
      ofType(DELETE_EXTRAS),
      switchMap(({ id }) => {
        return new Observable(async (observer) => {
          try {
            const result = await axiosClient.delete(
              `${DELETE_EXTRAS_API}${id}`
            );
            const { data } = result;
            if (data.success) {
              observer.next({
                type: DELETE_EXTRAS_SUCCESS,
                id: data.data.id,
                message: "Extras Deleted Successful",
              });
            } else {
              observer.next({
                type: DELETE_EXTRAS_FAILED,
                message: "Extras delete failed",
              });
            }
          } catch (err) {
            observer.next({
              type: DELETE_EXTRAS_FAILED,
              message: "Error in deleting Extras",
            });
          }
        });
      })
    );
  static getAllExtras = (action$) =>
    action$.pipe(
      ofType(GET_ALL_EXTRAS),
      switchMap(() => {
        return new Observable(async (observer) => {
          try {
            const result = await axiosClient.get(`${GET_ALL_EXTRAS_API}`);
            const { data } = result;
            if (data.success) {
              observer.next({
                type: GET_ALL_EXTRAS_SUCCESS,
                payload: data.data,
                message: "Get all extras successful",
              });
            } else {
              observer.next({
                type: GET_ALL_EXTRAS_FAILED,
                message: "Get all extras failed",
              });
            }
          } catch (err) {
            observer.next({
              type: GET_ALL_EXTRAS_FAILED,
              message: "Error in getting all extras",
            });
          }
        });
      })
    );
  static getExtrasDetail = (action$) =>
    action$.pipe(
      ofType(GET_EXTRAS_DETAIL),
      switchMap(({ id, showModal }) => {
        return new Observable(async (observer) => {
          try {
            const result = await axiosClient.get(
              `${GET_EXTRAS_DETAIL_API}${id}`
            );
            const {
              data: { data: optionList },
            } = await axiosClient.get(`${GET_EXTRAS_OPTIONS_API}?id=${id}`);

            const contract_locations = await axiosClient.post(
              `${GET_EXTRAS_COUNTRIES_LOCATION_API}`,
              {
                contract_id:
                  result.data &&
                  result.data.data.contracts.map((v) => v.contract_id),
                countries:
                  result &&
                  result.data.data.pickup_countries.map((v) => {
                    return {
                      code: v.code,
                    };
                  }),
              }
            );

            const contract_regions = await axiosClient.post(
              `${GET_EXTRAS_CONTRACT_LOCATIONS_API}`,
              {
                contracts:
                  result.data &&
                  result.data.data.contracts.map((v) => v.contract_id),
              }
            );

            const {
              data: { data: result_data },
            } = result;

            let contracts = optionList.contracts.map((val) => {
              let contractFound =
                result_data &&
                result_data.contracts.find((v) => v.contract_id === val.id);
              if (contractFound) {
                return {
                  ...val,
                  is_selected: true,
                  contract_id: contractFound.contract_id,
                };
              } else {
                return val;
              }
            });

            let platforms = optionList.platforms.map((val) => {
              let platformFound =
                result_data &&
                result_data.platforms.find((v) => v.platform_id === val.id);
              if (platformFound) {
                return {
                  ...val,
                  is_selected: true,
                  platform_id: platformFound.id,
                };
              } else {
                return val;
              }
            });

            let vehicles = optionList.vehicles.map((val) => {
              let VehicleFound =
                result_data &&
                result_data.vehicles.find((v) => v.car_group === val.id);
              if (VehicleFound) {
                return {
                  ...val,
                  is_selected: true,
                  car_group_id: VehicleFound.id,
                };
              } else {
                return val;
              }
            });

            let locations =
              optionList &&
              optionList.contractLocations &&
              optionList.contractLocations.location.map((val) => {
                let locationFound =
                  result_data &&
                  result_data.location.find(
                    (v) => v.supplier_location_id === val.id
                  );
                if (locationFound) {
                  return {
                    ...val,
                    is_selected: true,
                    pricing_rule_location_id: locationFound.id,
                  };
                } else {
                  return val;
                }
              });

            let pickup_countries =
              optionList &&
              optionList.contractLocations &&
              optionList.contractLocations.pickup_countries.map((val) => {
                let CountryFound =
                  result_data &&
                  result_data.pickup_countries.find(
                    (v) => v.country_id === val.id
                  );
                if (CountryFound) {
                  return {
                    ...val,
                    is_selected: true,
                    pricing_rule_country_id: CountryFound.id,
                  };
                } else {
                  return val;
                }
              });
            let region =
              optionList &&
              optionList.contractLocations &&
              optionList.contractLocations.region.map((val) => {
                let RegionFound =
                  result_data &&
                  result_data.region.find((v) => v.region === val.region);
                if (RegionFound) {
                  return {
                    ...val,
                    is_selected: true,
                    pricing_rule_region: RegionFound.id,
                  };
                } else {
                  return val;
                }
              });
            let residence_countries =
              optionList &&
              optionList.contractLocations &&
              optionList.contractLocations.residence_countries.map((val) => {
                let CountryFound =
                  result_data &&
                  result_data.residence_countries.find(
                    (v) => v.country_id === val.id
                  );
                if (CountryFound) {
                  return {
                    ...val,
                    is_selected: true,
                    pricing_rule_country_id: CountryFound.id,
                  };
                } else {
                  return val;
                }
              });

            const { data } = result;
            if (data.success) {
              observer.next({
                type: GET_EXTRAS_DETAIL_SUCCESS,
                payload: data.data,
                optionList: {
                  contracts: contracts,
                  vehicles: vehicles,
                  platforms: platforms,
                  location: contract_locations.data.data.map((v) => {
                    if (
                      result_data.location.find(
                        (x) => x.supplier_location_id === v.supplier_location_id
                      )
                    ) {
                      return {
                        ...v,
                        name: v.label,
                        is_selected: true,
                      };
                    } else {
                      return {
                        ...v,
                        name: v.label,
                        is_selected: false,
                      };
                    }
                  }),
                  region: contract_regions.data.data.region.map((v) => {
                    if (result_data.region.find((x) => x.name === v.name)) {
                      return {
                        ...v,
                        is_selected: true,
                      };
                    } else {
                      return {
                        ...v,
                        is_selected: false,
                      };
                    }
                  }),
                  pickup_countries:
                    result_data &&
                    result_data.pickup_countries.map((v) => {
                      return {
                        ...v,
                        is_selected: true,
                      };
                    }),
                  residence_countries: residence_countries,
                },
                message: "Extras details fetch successful",
              });
              showModal("editExtrasModal");
            } else {
              observer.next({
                type: GET_EXTRAS_DETAIL_FAILED,
                message: "Extras details fetch failed",
              });
            }
          } catch (err) {
            observer.next({
              type: GET_EXTRAS_DETAIL_FAILED,
              message: "Error in get extras details",
            });
          }
        });
      })
    );
  static getExtrasOptions = (action$) =>
    action$.pipe(
      ofType(GET_EXTRAS_OPTIONS),
      switchMap(() => {
        return new Observable(async (observer) => {
          try {
            const result = await axiosClient.get(`${GET_EXTRAS_OPTIONS_API}`);
            const { data } = result;
            if (data.success) {
              observer.next({
                type: GET_EXTRAS_OPTIONS_SUCCESS,
                payload: data.data,
                message: "Extras Options fetch successful",
              });
            } else {
              observer.next({
                type: GET_EXTRAS_OPTIONS_FAILED,
                message: "Extras Options fetch failed",
              });
            }
          } catch (err) {
            observer.next({
              type: GET_EXTRAS_OPTIONS_FAILED,
              message: "Error in get Extras Options",
            });
          }
        });
      })
    );
  static getExtrasContractLocations = (action$, state$) =>
    action$.pipe(
      ofType(GET_EXTRAS_CONTRACT_LOCATIONS),
      switchMap(({ payload }) => {
        return new Observable(async (observer) => {
          try {
            const result = await axiosClient.post(
              `${GET_EXTRAS_CONTRACT_LOCATIONS_API}`,
              payload
            );

            const { data } = result;
            if (data.success) {
              const state$_region = state$.value.Extras.optionsList.region;

              const regions = data.data.region.map((v) => {
                if (
                  state$_region.find((x) => x.name === v.name && x.is_selected)
                ) {
                  return {
                    ...v,
                    is_selected: true,
                  };
                } else {
                  return {
                    ...v,
                    is_selected: false,
                  };
                }
              });
              observer.next({
                type: GET_EXTRAS_CONTRACT_LOCATIONS_SUCCESS,
                payload: {
                  region: regions,
                  residence_countries: data.data.residence_countries,
                },
                message: "Extras contract locations fetch successful",
              });
            } else {
              observer.next({
                type: GET_EXTRAS_CONTRACT_LOCATIONS_FAILED,
                message: "Extras contract locations fetch failed",
              });
            }
          } catch (err) {
            observer.next({
              type: GET_EXTRAS_CONTRACT_LOCATIONS_FAILED,
              message: "Error in get Extras contract locations",
            });
          }
        });
      })
    );
  static getExtrasRegionCountries = (action$, state$) =>
    action$.pipe(
      ofType(GET_EXTRAS_REGION_COUNTRIES),
      switchMap(({ payload }) => {
        return new Observable(async (observer) => {
          try {
            const result = await axiosClient.post(
              `${GET_EXTRAS_REGION_COUNTRIES_API}`,
              payload
            );
            const { data } = result;
            const state$_pickupCountries =
              state$.value.Extras.optionsList.pickup_countries;

            const pickup_countries = data.data.map((v) => {
              if (
                state$_pickupCountries.find(
                  (x) => x.code === v.code && x.is_selected
                )
              ) {
                return {
                  ...v,
                  is_selected: true,
                };
              } else {
                return {
                  ...v,
                  is_selected: false,
                };
              }
            });
            if (data.success) {
              observer.next({
                type: GET_EXTRAS_REGION_COUNTRIES_SUCCESS,
                payload: pickup_countries,
                message: "Extras countries fetched successful",
              });
            } else {
              observer.next({
                type: GET_EXTRAS_REGION_COUNTRIES_FAILED,
                message: "Extras countries fetch failed",
              });
            }
          } catch (err) {
            observer.next({
              type: GET_EXTRAS_REGION_COUNTRIES_FAILED,
              message: "Error in get Extras countries",
            });
          }
        });
      })
    );
  static getExtrasCountriesLocations = (action$, state$) =>
    action$.pipe(
      ofType(GET_EXTRAS_COUNTRIES_LOCATIONS),
      switchMap(({ payload }) => {
        return new Observable(async (observer) => {
          try {
            const result = await axiosClient.post(
              `${GET_EXTRAS_COUNTRIES_LOCATION_API}`,
              payload
            );
            const { data } = result;
            if (data.success) {
              const state$_location = state$.value.Extras.optionsList.location;

              const location = data.data.map((v) => {
                if (
                  state$_location.find(
                    (x) =>
                      x.supplier_location_id === v.supplier_location_id &&
                      x.is_selected
                  )
                ) {
                  return {
                    ...v,
                    is_selected: true,
                    name: v.label,
                  };
                } else {
                  return {
                    ...v,
                    is_selected: false,
                    name: v.label,
                  };
                }
              });
              observer.next({
                type: GET_EXTRAS_COUNTRIES_LOCATIONS_SUCCESS,
                payload: location,
                message: "Extras countries locations fetched successful",
              });
            } else {
              observer.next({
                type: GET_EXTRAS_COUNTRIES_LOCATIONS_FAILED,
                message: "Extras countries locations fetch failed",
              });
            }
          } catch (err) {
            observer.next({
              type: GET_EXTRAS_COUNTRIES_LOCATIONS_FAILED,
              message: "Error in get Extras countries locations",
            });
          }
        });
      })
    );
}
