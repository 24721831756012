import {
    GET_PAYMENT_MODE,
    GET_PAYMENT_MODE_FAILED,
    GET_PAYMENT_MODE_SUCCESS,
    SET_PAYMENT_MODE,
    SET_PAYMENT_MODE_FAILED,
    SET_PAYMENT_MODE_SUCCESS,
  } from "../actions/switchPayment.action";
  
  const INITIAL_STATE = {
    res : "",
  };
  
  export const SwitchPaymentReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case GET_PAYMENT_MODE:
        return {
          ...state,
          isLoading: true,
          isError: false,
          message: "",
          res: false,
        };
      case GET_PAYMENT_MODE_SUCCESS:
        return {
          ...state,
          isLoading: false,
          isError:false,
          res : action.payload,
          message: action.message,
        };
      case GET_PAYMENT_MODE_FAILED:
        return {
          ...state,
          isLoading: false,
          message: action.message,
          isError: true,
        };
      case SET_PAYMENT_MODE:
        return {
            ...state,
          isLoading: true,
          isError: false,
          message: "",
          res: false,
        };
        case SET_PAYMENT_MODE_SUCCESS:
          return {
          ...state,
          isLoading: false,
          isError:false,
          res : action.payload,
          message: action.message,
        };
        case SET_PAYMENT_MODE_FAILED:
          return {
            ...state,
            isLoading: false,
            message: action.message,
            isError: true,
          };
      
      default:
        return state;
    }
  };
  