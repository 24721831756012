import {
  GET_INTEGRATIONS,
  GET_INTEGRATIONS_FAILED,
  GET_INTEGRATIONS_SUCCESS,
  GET_SUPPLIERS,
  GET_SUPPLIERS_FAILED,
  GET_SUPPLIERS_SUCCESS
} from "./../actions/supply.action";

const INITIAL_STATE = {
  integrations: [],
  suppliers: [],
  isLoading: false,
  isError: false,
  message: ""
};

export const SupplyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_INTEGRATIONS:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: ""
      };
    case GET_INTEGRATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        integrations: action.payload,
        message: action.message
      };
    case GET_INTEGRATIONS_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.mesage
      };
    case GET_SUPPLIERS:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: ""
      };
    case GET_SUPPLIERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        suppliers: action.payload,
        message: action.message
      };
    case GET_SUPPLIERS_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.mesage
      };
    default:
      return state;
  }
};
